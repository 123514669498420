@import "../../../styles/config";

.root {
  box-sizing: border-box;
  background-color: white;
  border: #e5eaef 1px solid;
  border-radius: 12px;

  /* padding size */
  &.small {
    padding: 16px;
  }
  &.medium {
    padding: 24px;
  }
}
