@import "../../../styles/config";

.root {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon {
  display: flex;
  align-items: center;
}

.date {
  margin-left: 4px;
  @media (min-width: $breakpoint-sm) {
    margin-left: 16px;
  }
}

.button {
  display: flex;
  align-items: center;
  height: 26px;
  border: 0;
  background: transparent;
  cursor: pointer;
  @include body-lg;
  @media (min-width: $breakpoint-sm) {
    @include heading-5;
  }
}

.buttonIcon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  @media (min-width: $breakpoint-sm) {
    margin-left: 12px;
  }
}

.popover {
  width: 320px;
}

.popoverHeader {
  padding: 20px 24px 16px 24px;
}

.popoverTitle {
  padding-bottom: 12px;
}

.customPanel {
  padding: 24px 24px 12px 24px;
}

.customPanelFooter {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding-bottom: 12px;
}

.optionList {
  list-style: none;
  margin: 0;
  padding: 8px 0;
}

.option {
  display: flex;
  align-items: center;
  padding: 8px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $wolf-600;
  font-family: Lato;
  &:hover {
    background: $wolf-100;
  }

  span {
    flex: 1;
    display: block;
    height: 24px;
  }

  svg {
    width: 24px;
    height: 24px;
    display: none;
    color: $wolf-800;
  }

  &.selected {
    color: $wolf-800;
    svg {
      display: block;
    }
  }
}
