@import "../../../styles/config";

.root {
  display: flex;
  width: 100%;
  > .child {
    flex: 1;
    position: relative;
    &:focus-within {
      z-index: 1;
    }
    &:hover {
      z-index: 1;
    }
    &:not(:first-child) {
      margin-left: -1px;
    }
  }
}
