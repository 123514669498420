.container {
  background: white;
  font-family: Lato;
  font-style: normal;
  padding: 30px;
  max-width: 390px;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

.date-inputs-container {
  display: flex;
}

.date-input-holder {
  flex: 1;
  width: calc(50% - 20px);
  margin-bottom: 20px;

  &:last-child {
    margin-left: 15px;
  }
}

.react-datepicker__navigation {
  background: none;
  border: none;
}

.label {
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}

.date-input {
  font-family: Lato;
  font-size: 14px;
  background: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 6px 10px;
  width: 100%;
  margin-top: 4px;
}
