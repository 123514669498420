@import "../../../styles/config";

.previewred {
  background-color: red;
  height: 230px;
}

.previewblue {
  background-color: blue;
  height: 200px;
}

.previewgreen {
  background-color: green;
  height: 200px;
}

.slideparent {
  @include hide-scrollbar;
  position: fixed;
  transition: 500ms;
  top: 0px;
  bottom: 0px;
  width: 100%;
  max-width: 100%;
  background: #ffffff;
  z-index: $modal-z-index;
  overflow-y: auto;
  box-shadow: -8px 0 32px 0 rgba(0, 0, 0, 0.24);
}

.slideparent-closed {
  right: -800px;
}

.slideparent-open {
  right: 0px;
}

@media (min-width: 768px) {
  .slideparent {
    width: 40%;
    max-width: 400px;
  }
}
