@import "../../../styles/config";

.container {
  width: max-content;
  padding: $grid-unit 0;
  background-color: $white;
  border: 1px solid $border;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

  @media (max-width: $breakpoint-mobile) {
    padding: $grid-unit-05 0;
  }
}

/* MenuItem */
.menu-item,
.cta-item {
  @include body-lg;
  cursor: pointer;
  display: block;
  width: 100%;
  padding: $grid-unit $grid-unit-30;
  text-align: left;
  line-height: $grid-unit-20;
  color: $wolf-800;
  background-color: white;
  text-decoration: none;

  &:hover {
    background-color: $pacific-primary-100;
  }
  &:focus {
    background-color: $pacific-primary-100;
  }

  @media (max-width: $breakpoint-mobile) {
    @include font-size-body-md;
    padding: $grid-unit $grid-unit-20;
  }
}

/* MenuItemGroup */
.menu-group:not(:first-of-type) {
  margin-top: $grid-unit;
}

.menu-group-title {
  @include body-md;
  line-height: $grid-unit-15;
  display: block;
  padding: $grid-unit $grid-unit-30;
  color: $wolf-600;
  margin-bottom: $grid-unit-025;
}

/* CTAItem */
.cta-item {
  display: flex;
  align-items: center;
  gap: $grid-unit;
}

.cta-icon {
  @include icon-size($grid-unit-20);
  color: $wolf-800;
}

/* CTAGroup */
.cta-group {
  border-top: 1px solid $border;
  margin-top: $grid-unit;
  padding-top: $grid-unit;
}

.menu-item-icon {
  margin-right: $grid-unit;
}
