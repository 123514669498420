@import "../../../styles/config";

.root {
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $wolf-400;
    &:hover {
      background: $wolf-500;
    }
  }
}
