.pp-block-picker {
  font-family: Lato;
  text-transform: uppercase;
}

.storybook-preview {
  display: flex;
  align-items: center;
}

.box {
  width: 200px;
  margin-left: 10px;
  height: 34px;
}

.parent {
  -webkit-box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.2);
  display: inline-flex;
  border-radius: 12px;
}
