@import "../../../styles/config";

.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &.stickyFooter {
    .footer {
      position: sticky;
      bottom: 0;
    }
  }
}

.content {
  flex: 1;

  padding: 24px;
}

.left {
  position: relative;

  @media (max-width: $breakpoint-sm) {
    width: 45%;
  }
}

.right {
  position: relative;

  @media (max-width: $breakpoint-sm) {
    width: 45%;
  }
}

.footer {
  flex: 0;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $wolf-200;
  padding: 20px 24px;
  background: $white;

  @media (max-width: $breakpoint-sm) {
    padding: 20px 40px;
  }
}
