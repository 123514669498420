@mixin reset {
  margin: 0;
  padding: 0;
  border: none;
  line-height: 0;
  outline: none;
  box-sizing: border-box;
}

@mixin icon-size($size) {
  width: $size;
  height: $size;
  min-width: $size;
  max-width: $size;
}

@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@mixin background-color($prefix) {
  @include color-prop($prefix, "background-color");
}

@mixin border-color($prefix) {
  @include color-prop($prefix, "border-color");
}

@mixin text-color($prefix) {
  @include color-prop($prefix, "color");
}

@mixin color-prop($prefix, $prop) {
  // add colors
  @each $color in $colors {
    @if ($color != "wolf") {
      .#{$prefix}#{$color} {
        $group: map-get($colors-map, $color);
        #{$prop}: map-get($group, "default") !important;
      }
    }
  }
  // add colors with tones
  @each $color in $colors {
    @each $tone in $tones {
      @if not($color == "wolf") {
        .#{$prefix}#{$color}-#{$tone} {
          $group: map-get($colors-map, $color);
          #{$prop}: map-get($group, $tone) !important;
        }
      }
    }
  }
  // wolf colors
  @each $tone in $tones {
    @if not($tone == "1000") {
      .#{$prefix}wolf-#{$tone} {
        $group: map-get($colors-map, "wolf");
        #{$prop}: map-get($group, $tone) !important;
      }
    }
  }
  // single colors
  .#{$prefix}black {
    #{$prop}: $black !important;
  }
  .#{$prefix}white {
    #{$prop}: $white !important;
  }
}

@mixin checkered-bg($size, $color) {
  background-image: linear-gradient(45deg, $color 25%, transparent 25%),
    linear-gradient(-45deg, $color 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, $color 75%),
    linear-gradient(-45deg, transparent 75%, $color 75%);
  background-size: $size $size;
  background-position: 0 0, 0 calc(#{$size}/ 2),
    calc(#{$size}/ 2) calc(#{$size}/ 2 * -1), calc(#{$size}/ 2 * -1) 0px;
}

@mixin screen-reader-only() {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
