@import "../../../styles/config";

.overlay {
  position: fixed;
  inset: 0;
  z-index: $popover-z-index;
}

.root {
  position: relative;
  z-index: $popover-z-index;
  .container {
    display: block;
    box-sizing: border-box;
    border: 1px solid $wolf-300;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    background: $white;
    border-radius: 4px;
    outline: none;
    &.paddingSmall {
      padding: 8px;
    }
    &.paddingMedium {
      padding: 16px;
    }
    &.paddingLarge {
      padding: 20px;
    }
    &.disableShadow {
      box-shadow: none;
    }
  }
}
