@import "../../../../styles/config";

.list {
  list-style: none;
  max-height: 300px;
  overflow-y: auto;
  margin: 8px 0;
  padding: 0;
  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $wolf-200;
    &:hover {
      background: $wolf-300;
    }
  }
}

.option {
  display: block;
  font-size: 14px;
  font-family: "Lato";
  padding: 8px 16px;
  color: $wolf-800;
  cursor: pointer;

  &:hover {
    background: $wolf-100;
  }
}

.optionGroup {
  display: block;
  font-size: 11px;
  font-family: "Lato";
  padding: 8px 16px;
  color: $wolf-600;
}

.subList {
  list-style: none;
  margin: 0;
  padding: 0;
}
