@import "../../../styles/config";

.container {
  display: flex;

  .date-container {
    width: 375px;
    background: $white;
    padding: $grid-unit-20;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid $border;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  }

  .input-time-content {
    width: 100%;
  }

  .left-dropdown {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .right-dropdown {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .select-wrapper {
    position: relative;
  }

  .click-wrapper {
    cursor: pointer;
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
