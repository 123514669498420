@import "../../../styles/config";

$small-breakpoint: 359px;

.container {
  display: flex;
  flex-direction: column;
  width: max-content;

  &.light {
    .month-title {
      color: $wolf-800;
    }

    .week-nav-icon {
      color: $wolf-800;
    }
  }

  &.dark {
    .month-title {
      color: white;
    }

    .week-nav-icon {
      color: $white;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    width: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.week-nav {
  display: flex;
  justify-content: space-between;
  gap: $grid-unit;
}

.month-title {
  color: white;
}

.day-items {
  display: flex;
  justify-content: space-between;
  gap: $grid-unit;
  margin-top: $grid-unit-30;

  @media (max-width: $small-breakpoint) {
    gap: 4px;
  }
}

.day-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: $grid-unit;
  width: 44px;
  height: $grid-unit-80;
  border: 1px solid;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: $small-breakpoint) {
    padding-left: 2px;
    padding-right: 2px;
  }

  .short-day-name {
    @include body-sm;

    @media (max-width: $small-breakpoint) {
      font-size: 0;
      &::first-letter {
        @include heading-5;
        color: $brand;
      }
    }
  }

  .date {
    @include heading-4;
    font-weight: 700;
    margin-top: $grid-unit-025;

    @media (max-width: $small-breakpoint) {
      @include heading-5;
      font-weight: 600;
    }
  }

  &.light {
    background-color: $white;
    border-color: $brand;

    .date,
    .short-day-name {
      color: $brand;
    }

    &.selected {
      pointer-events: none;
      background-color: $brand;

      .date,
      .short-day-name {
        color: $white;

        &::first-letter {
          color: $white;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      border-color: $border;

      .date,
      .short-day-name {
        color: $disabled;
      }
    }

    &:hover {
      outline: $pacific-primary-100 $grid-unit-05 solid;
    }
  }

  &.dark {
    background-color: $brand;
    border-color: $white;

    .date,
    .short-day-name {
      color: $white;

      @media (max-width: $small-breakpoint) {
        &::first-letter {
          color: $white;
        }
      }
    }

    &.selected {
      pointer-events: none;
      background-color: $white;

      .date,
      .short-day-name {
        color: $brand;

        @media (max-width: $small-breakpoint) {
          &::first-letter {
            color: $brand;
          }
        }
      }
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      outline: $pacific-primary-500 $grid-unit-05 solid;
    }
  }
}
