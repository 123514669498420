@import "../../../styles/config";

$kebab-width: $grid-unit-20;
$kebab-width-mobile: $grid-unit-30;

.container {
  width: 100%;
  background-color: white;
  border: #e5eaef 1px solid;
  border-radius: 12px;
}

.header-row {
  $header-gap: $grid-unit-20;
  $header-gap-mobile: $grid-unit;

  padding: $grid-unit-15 $grid-unit-30;
  padding-right: calc($grid-unit-30 + $header-gap + $kebab-width);
  display: flex;
  font-weight: bold;
  align-items: center;
  border-bottom: #e5eaef 1px solid;
  gap: $header-gap;

  @media (max-width: $breakpoint-mobile) {
    padding: $grid-unit $grid-unit-15;
    padding-right: calc(
      $grid-unit-15 + $header-gap-mobile + $kebab-width-mobile
    );
    gap: $header-gap-mobile;
  }
}

.kebab {
  width: $kebab-width;

  svg {
    @include icon-size($grid-unit-30);
    color: $black;

    @media (max-width: $breakpoint-mobile) {
      @include icon-size($grid-unit-20);
    }
  }

  @media (max-width: $breakpoint-mobile) {
    width: $kebab-width-mobile;
  }
}

.empty-view {
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-title {
  @include heading-4;
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;
  color: $black;
  margin-top: $grid-unit-30;
}

/* HeaderCell */
.header-cell {
  display: flex;
  align-items: center;
  flex: 1;

  span {
    @include body-lg;
    font-weight: 700;
    margin-right: $grid-unit;

    @media (max-width: $breakpoint-mobile) {
      @include font-size-body-md;
    }
  }
}

/* HeaderSelectCell */
.header-select-cell {
  position: relative;
  display: flex;
  align-items: center;
  &.has-arrow-button {
    .arrow-button {
      position: absolute;
      left: 20px;
    }
    & + .header-cell > span {
      margin-left: 16px;
    }
  }
}

.sort-btn {
  @include reset;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: $grid-unit;

    &:last-of-type {
      margin-left: $grid-unit-025;
      padding-top: $grid-unit-025;

      @media (max-width: $breakpoint-mobile) {
        margin-left: 1px;
      }
    }

    @media (max-width: $breakpoint-mobile) {
      width: 6px;
    }
  }
}

/* TableRow */
.table-row {
  display: flex;
  align-items: center;
  padding: $grid-unit-15 $grid-unit-30;
  border-bottom: #e5eaef 1px solid;
  gap: $grid-unit-20;

  &:last-of-type {
    border-bottom: none;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: $grid-unit $grid-unit-15;
    gap: $grid-unit;
  }
}

/* TableCells */
.table-cell {
  flex: 1;
}

.label-cell {
  @include body-lg;

  @media (max-width: $breakpoint-mobile) {
    @include font-size-body-md;
  }
}

.missing-data-cell {
  @include body-lg;
  color: $wolf-500;

  @media (max-width: $breakpoint-mobile) {
    @include font-size-body-md;
  }
}

.title-subtitle-cell {
  .title {
    @include body-lg;
    color: $black;
    margin-bottom: 2px;

    @media (max-width: $breakpoint-mobile) {
      @include font-size-body-md;
      margin-bottom: 0;
    }
  }

  .subtitle {
    @include body-lg;
    color: $wolf-500;

    @media (max-width: $breakpoint-mobile) {
      @include font-size-body-md;
    }
  }
}

.name-monogram-cell {
  display: flex;
  align-items: center;

  .monogram {
    margin-right: $grid-unit-20;

    @media (max-width: $breakpoint-mobile) {
      margin-right: $grid-unit;
    }
  }
}

/* TablePagination */
.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: $grid-unit-15 $grid-unit-30;
  border-top: 1px solid $border;

  svg {
    @include icon-size($grid-unit-30);

    @media (max-width: $breakpoint-mobile) {
      @include icon-size($grid-unit-20);
    }
  }

  @media (max-width: $breakpoint-mobile) {
    padding: $grid-unit $grid-unit-15;
  }
}

.page-info {
  width: max-content;
  display: flex;
  align-items: center;
  padding: 0 $grid-unit-30;

  span {
    @include body-lg;

    @media (max-width: $breakpoint-mobile) {
      @include font-size-body-md;
    }
  }

  .page-count {
    display: inline-block;
    margin-left: $grid-unit-05;
  }

  @media (max-width: $breakpoint-mobile) {
    padding: 0 $grid-unit-20;
  }
}

.page-input {
  @include body-lg;
  text-align: center;
  max-width: $grid-unit-50;
  padding: $grid-unit-05 $grid-unit;
  margin-right: $grid-unit-15;
  background-color: $white;
  border: 1px solid $border;
  border-radius: 4px;

  @media (max-width: $breakpoint-mobile) {
    @include font-size-body-md;
    max-width: $grid-unit-40;
    padding: $grid-unit-025;
    margin-right: $grid-unit;
  }
}

// no menu
.no-menu {
  .header-row {
    padding-right: 24px;
  }
}

// simple variant
.simple {
  background-color: initial;
  border: none;
  border-radius: 0;
  .header-row {
    padding-top: 4px;
    padding-right: 44px;
    padding-bottom: 4px;
    padding-left: 0;
    border-bottom: none;
  }
  .table-row {
    padding-top: 4px;
    padding-right: 12px;
    padding-bottom: 4px;
    padding-left: 0;
    border-bottom: none;
  }
  &.no-menu {
    .header-row {
      padding-right: 0;
    }
    .table-row {
      padding-right: 0;
    }
  }
}
