.text {
  padding: 2em;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  line-height: 1.6;
  text-align: left;
}

.button {
  display: flex;
  align-items: center;
  margin: 1em 0.5em 0.5em 1.3125em;
}

.separator {
  width: 100%;
  border-bottom: solid 1px;
  position: relative;
  margin: 1.2rem 0;
  border-color: #b8b8b8;
}
