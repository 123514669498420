.parent {
  display: flex;
  align-items: center;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

.co-joined-parent {
  width: 100%;
  display: flex;
  align-items: center;
}

.innerparent select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  background-color: transparent;
  border: none;
  //padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: Lato;
  font-size: 14px;
  cursor: inherit;
  line-height: inherit;
  position: relative;
  padding: 6px 10px;

  // Stack above custom arrow
  z-index: 1;

  // Remove dropdown arrow in IE10 & IE11
  // @link https://www.filamentgroup.com/lab/select-css.html
  &::-ms-expand {
    display: none;
  }

  // Remove focus outline, will add on alternate element
  outline: none;
}

.delete {
  background: none;
  outline: none;
  resize: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
}

.delete {
  transform: scale(0.85);
}

.innerparent {
  flex: 1;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  border: 1px solid #cccccc;

  .innerparent select,
  &::after {
    grid-area: select;
  }

  min-width: 8ch;

  cursor: pointer;

  // Optional styles
  // remove for transparency
  //   background-color: #fff;
  //   background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

  // Custom arrow
  &:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    position: absolute;
  }

  &:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: none;
  }

  &:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
  }
}

.innerparent svg {
  position: absolute;
  right: 8px;
  transform: scale(0.7);
  z-index: 0;
}

// Interim solution until :focus-within has better support
.innerparent select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}

.innerparent select[multiple] {
  padding-right: 0;

  /*
   * Safari will not reveal an option
   * unless the select height has room to 
   * show all of it
   * Firefox and Chrome allow showing 
   * a partial option
   */
  height: 6rem;

  .innerparent select option {
    white-space: normal;

    // Only affects Chrome
    outline-color: var(--select-focus);
  }
}

.select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}

.parent + label {
  margin-top: 2rem;
}
