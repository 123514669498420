.inputText {
  border: none;
  outline: none;
  box-sizing: border-box;
  flex: 1;
}

.parent {
  position: relative;
}

.container {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  max-width: 350px;
}

.datecontainer {
  position: absolute;
  border-radius: 4px;
  top: 110%;
  max-width: 350px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  z-index: 4;
  padding: 20px;
}

.container button {
  border: none;
  outline: none;
  resize: none;
  background: none;
  padding: 0px;
  display: flex;
}

.text {
  font-family: Lato;
  color: #2a3540;
  margin: 1px;
}
