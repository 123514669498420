@import "../../../../../styles/config.scss";

.row {
  display: flex;
  padding: 0.5em 0;
}

.halfRow {
  display: flex;
  padding: 0.5em 0;
  width: 50%;
}

.circle {
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 50%;
  font-size: 1em;
  color: #fff;
  text-align: center;
  background: $pacific-primary-600;
}
.column {
  flex: 50%;
  padding: 0em 1em;
}

.three-column {
  flex: 33.3%;
  padding: 0em 1em;
}
