@import "@pushpress/shared-components/build/styles/config.scss";

@for $i from 1 through 20 {
  .m-#{$i} {
    margin: $grid-unit * $i !important;
  }

  .mt-#{$i} {
    margin-top: $grid-unit * $i !important;
  }
  .mr-#{$i} {
    margin-right: $grid-unit * $i !important;
  }
  .mb-#{$i} {
    margin-bottom: $grid-unit * $i !important;
  }
  .ml-#{$i} {
    margin-left: $grid-unit * $i !important;
  }
}

@for $i from 1 through 20 {
  .p-#{$i} {
    padding: $grid-unit * $i !important;
  }

  .pt-#{$i} {
    padding-top: $grid-unit * $i !important;
  }
  .pr-#{$i} {
    padding-right: $grid-unit * $i !important;
  }
  .pb-#{$i} {
    padding-bottom: $grid-unit * $i !important;
  }
  .pl-#{$i} {
    padding-left: $grid-unit * $i !important;
  }
}
