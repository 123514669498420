@import "../../../styles/config";

.container {
  --status-chip-bg-color: #{$pacific-primary-200};
  --status-chip-text-color: #{$pacific-primary-600};

  @include reset;
  background-color: var(--status-chip-bg-color);
  border-radius: 4px;
  width: max-content;
  height: fit-content;

  span {
    @include body-sm;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--status-chip-text-color);

    @media (max-width: $breakpoint-mobile) {
      font-size: 8px;
      line-height: 14px;
    }
  }

  /* Variants */
  &.neutral {
    --status-chip-bg-color: #{$pacific-primary-600};
    --status-chip-text-color: #{$white};
  }
  &.neutral-reverse {
    --status-chip-bg-color: #{$white};
    --status-chip-text-color: #{$pacific-primary-600};
  }
  &.link {
    --status-chip-bg-color: #{$pacific-primary-200};
    --status-chip-text-color: #{$pacific-primary-600};
  }
  &.success {
    --status-chip-bg-color: #{$inch-worm-200};
    --status-chip-text-color: #{$inch-worm-600};
  }
  &.warning {
    --status-chip-bg-color: #{$golden-rod-200};
    --status-chip-text-color: #{$golden-rod-600};
  }
  &.error {
    --status-chip-bg-color: #{$wild-watermellon-200};
    --status-chip-text-color: #{$wild-watermellon-600};
  }
  &.info {
    --status-chip-bg-color: #{$sea-foam-200};
    --status-chip-text-color: #{$sea-foam-600};
  }
  &.white {
    --status-chip-bg-color: #{$white};
    --status-chip-text-color: #{$wolf-800};
    border: 1px solid #{$wolf-800};
  }
  &.gray {
    --status-chip-bg-color: #{$wolf-300};
    --status-chip-text-color: #{$wolf-800};
  }

  /* Sizes */
  &.medium {
    padding: $grid-unit-05 $grid-unit;
  }
  &.small {
    padding: $grid-unit-05 * 0.75 $grid-unit * 0.75;
    span {
      line-height: 12px;
    }
  }

  /* Loading */
  &.loading {
    background-color: #eee;
    background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    background-size: 100px 100%;
    background-repeat: no-repeat;
    animation: loading 1.2s ease-in-out infinite;
    span {
      color: transparent;
    }
  }
}

@keyframes loading {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}
