@import "../../../styles/config";

$arrow-tip-side: $grid-unit-15;
$arrow-tip-center: calc(50% - ($grid-unit-15 / 2));
$arrow-tip-offset: -$grid-unit-05;

.container {
  @include body-md;
  width: max-content;
  padding: $grid-unit $grid-unit-15;
  background-color: $wolf-900;
  border-radius: 3px;
  color: $white;
  text-align: center;
  position: relative;
  z-index: $tooltip-z-index;

  .arrow-tip {
    width: $arrow-tip-side;
    height: $arrow-tip-side;
    transform: rotateZ(45deg);
    background-color: $wolf-900;
    position: absolute;
    z-index: -1;
  }

  &.top {
    margin-top: $grid-unit;
    .arrow-tip {
      top: $arrow-tip-offset;
      left: $arrow-tip-center;
    }
  }
  &.bottom {
    margin-bottom: 8px;
    .arrow-tip {
      bottom: $arrow-tip-offset;
      left: $arrow-tip-center;
    }
  }
  &.left {
    margin-left: $grid-unit;
    .arrow-tip {
      bottom: $arrow-tip-center;
      left: $arrow-tip-offset;
    }
  }
  &.right {
    margin-right: $grid-unit;
    .arrow-tip {
      bottom: $arrow-tip-center;
      right: $arrow-tip-offset;
    }
  }
}
