@import "../../../../../styles/config.scss";

.blueContainer {
  background: $pacific-primary-600;
  display: flex;
  border-radius: 10px 10px 0 0;
  font-family: Lato;
}

.blueContainerText {
  padding: 5em 0em 0em 2em;
}

.train-landing {
  font-family: Lato;
}
.grow-body-landing {
  padding: 1em;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  padding: 1em;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
  font-size: 1.5em;
}

.growBodyLandingTitle {
  font-size: 1.5em;
  color: white;
  font-family: Lato;
}

.growBodyLandingSubtitle {
  padding: 1em 0 0 0;
  color: white;
  font-family: Lato;
}

.growBodyLandingButton {
  display: flex;
  padding: 2em 0;
}

.separator {
  width: 100%;
  border-bottom: solid 1px;
  position: relative;
  margin: 10px 0px;
  border-color: #b8b8b8;
}

.cellPhone {
  display: flex;
  height: 1330%;
  justify-content: end;
  align-items: center;
}

.affordablePricing {
  margin: 0 10em;
}

.buttons {
  display: flex;
  padding: 2em;
  justify-content: center;
  align-items: center;
}
