@import "../../../styles/config";

.parent {
  display: flex;
  align-items: center;
}

.innerparent {
  select {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    background-color: transparent;
    border: none;
    //padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: Lato;
    font-size: 14px;
    cursor: inherit;
    line-height: normal;
    position: relative;
    padding: 8px 12px;

    // Stack above custom arrow
    z-index: 1;

    // Remove dropdown arrow in IE10 & IE11
    // @link https://www.filamentgroup.com/lab/select-css.html
    &::-ms-expand {
      display: none;
    }

    // Remove focus outline, will add on alternate element
    outline: none;
  }

  select[disabled] {
    color: initial;
  }

  .select-placeholder {
    color: $wolf-500 !important;
  }
}

.delete {
  background: none;
  outline: none;
  resize: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
}

.delete {
  transform: scale(0.85);
}

.innerparent {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  .innerparent select,
  &::after {
    grid-area: select;
  }

  min-width: 15ch;
  width: 100%;
  border: 1px solid #dae0e6;
  border-radius: 5px;
  cursor: pointer;

  // Optional styles
  // remove for transparency
  background-color: #fff;

  // Custom arrow
  &:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    position: absolute;
  }
}

.innerparent svg {
  position: absolute;
  right: 8px;
  transform: scale(0.7);
}

// Interim solution until :focus-within has better support
.innerparent select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}

.innerparent select[multiple] {
  padding-right: 0;

  /*
   * Safari will not reveal an option
   * unless the select height has room to 
   * show all of it
   * Firefox and Chrome allow showing 
   * a partial option
   */
  height: 6rem;

  .innerparent select option {
    white-space: normal;

    // Only affects Chrome
    outline-color: var(--select-focus);
  }
}

.select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}

.parent + label {
  margin-top: 2rem;
}
