@import "../../../styles/config";

.root {
  position: relative;

  &.small {
    .item {
      margin-right: 8px;
    }
  }

  &.medium {
    .item {
      margin-right: 12px;
    }
  }
}

.startAdornment {
  display: inline-flex;
}

.list {
  display: inline;
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  display: inline;
  position: relative;
}
