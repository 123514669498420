@import "../../../styles/config";

.container {
  position: relative;
  flex: 1;
  display: flex;
  background-color: $white;
  border: 1px solid $border;
  border-radius: 5px;
  cursor: pointer;

  select {
    @include body-lg;
    background-color: transparent;
    border: none;
    width: 100%;
    padding: $grid-unit $grid-unit-20;
    z-index: 1;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;

    &:disabled {
      opacity: 1;
    }
  }

  svg {
    position: absolute;
    right: $grid-unit;
    top: $grid-unit-075;
    transform: scale(0.7);
  }

  .select-placeholder {
    @include body-lg;
    color: $wolf-500;
    position: absolute;
    top: $grid-unit;
    left: $grid-unit-20;
  }

  &.fit-content {
    flex: none;
    padding-right: 16px;
  }
}
