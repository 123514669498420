.container {
  width: 20rem;
  height: 40rem;

  /* white */
  background: #ffff;
  /* wolf-300 */
  border: 1px solid #ffff;
  box-sizing: border-box;
  border-radius: 1rem 1rem 0 0;
  transform: rotate(-180deg);
}

.grid {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 65rem;
}

.section {
  grid-column: 1 / 2;
  grid-row: 1;
}

.title {
  padding-right: 5rem;
}

.bulletList {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.separator {
  width: 100%;
  border-bottom: solid 1px;
  position: relative;
  margin: 1.2rem 0;
  border-color: #b8b8b8;
}
