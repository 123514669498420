/* The switch - the box around the slider */
@import "../../../styles/config";

.switch-parent {
  font-family: Lato;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.label {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  margin-bottom: 0;
}

/* Hide default HTML checkbox */
.switch-parent input {
  opacity: 0;
  width: 0;
  height: 0;
  outline: none;
  resize: none;
}

/* The slider */
.slider {
  box-sizing: border-box;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  border: 1px solid $wolf-500;
  transition: all 0.2s;
  &:hover {
    border: 1px solid $pacific-primary-600;
  }
}

.text {
  color: $wolf-800;
  padding-left: 8px;
  cursor: pointer;
  user-select: none;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 3px;
  background-color: $wolf-500;
  transition: all 0.2s;
}

input:checked + .switch .slider {
  border: 1px solid $pacific-primary-600;
  background-color: $pacific-primary-600;
  &:hover {
    background-color: $pacific-primary-500;
  }
  &:active {
    background-color: $pacific-primary-700;
  }
}

input:focus + .switch .slider {
  border: 1px solid $pacific-primary-600;
  box-shadow: 0 0 0 3px $pacific-primary-100;
}

input:active + .switch .slider {
  background-color: $white;
}

input:disabled + .switch .slider {
  cursor: default;
  background: $wolf-200;
  border-color: $wolf-300;
}

input:disabled:checked + .switch .slider {
  background: $wolf-500;
  border-color: $wolf-500;
}

input:disabled ~ .label {
  cursor: default;
  color: $wolf-500;
}

input:checked + .switch .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
  background-color: $white;
  height: 16px;
  width: 16px;
  left: 1px;
  bottom: 1px;
  border: 0;
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

// size
.switch-parent {
  &.small {
    .label {
      @include font-size-body-sm;
      padding-left: $grid-unit-10;
    }
  }
  &.medium {
    .label {
      @include font-size-body-lg;
    }
  }
  &.large {
    .label {
      @include font-size-heading-5;
    }
  }
  &.jumbo {
    .label {
      @include font-size-heading-5;
    }
  }
}
