@use "sass:math";
@import "../../../styles/config";

$columns: 12;
$spacing-unit: 8;
$breakpoints: (
  xs: 0,
  sm: $breakpoint-sm,
  md: $breakpoint-md,
  lg: $breakpoint-lg,
  xl: $breakpoint-xl,
);

@function get-spacing($spacing) {
  @return ($spacing * 8) + "px";
}

.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;

  @for $i from 1 through $spacing-unit {
    $spacing: get-spacing($i);

    &.col-spacing-#{$i} {
      width: calc(100% + #{$spacing});
      margin-left: -#{$spacing};

      > .item {
        padding-left: #{$spacing};
      }
    }

    &.row-spacing-#{$i} {
      margin-top: -#{$spacing};

      > .item {
        padding-top: #{$spacing};
      }
    }
  }
}

.item {
  margin: 0;
  min-width: 1px;
}

@mixin col-auto {
  width: auto;
}

@media (max-width: 600px) {
  .col-xs-auto {
    @include col-auto;
  }
}

@media (max-width: 900px) {
  .col-sm-auto {
    @include col-auto;
  }
}

@media (max-width: 1200px) {
  .col-md-auto {
    @include col-auto;
  }
}

@media (max-width: 1536px) {
  .col-lg-auto {
    @include col-auto;
  }
}

@function get-column-width($size) {
  @return math.div($size, $columns) * 100 + "%";
}

@for $i from 1 through $columns {
  $column-size: get-column-width($i);

  @media (min-width: 0px) {
    .col-xs-#{$i} {
      width: #{$column-size};
      max-width: #{$column-size};
    }
  }
}

@for $i from 1 through $columns {
  $column-size: get-column-width($i);

  @media (min-width: 600px) {
    .col-sm-#{$i} {
      width: #{$column-size};
    }
  }
}

@for $i from 1 through $columns {
  $column-size: get-column-width($i);

  @media (min-width: 900px) {
    .col-md-#{$i} {
      width: #{$column-size};
    }
  }
}

@for $i from 1 through $columns {
  $column-size: get-column-width($i);

  @media (min-width: 1200px) {
    .col-lg-#{$i} {
      width: #{$column-size};
    }
  }
}

@for $i from 1 through $columns {
  $column-size: get-column-width($i);

  @media (min-width: 1536px) {
    .col-xl-#{$i} {
      width: #{$column-size};
    }
  }
}

// item order
@each $size, $breakpoint in $breakpoints {
  @media (min-width: $breakpoint) {
    @for $i from 1 through $columns {
      .order-#{$size}-#{$i} {
        order: $i;
      }
    }
  }
}
