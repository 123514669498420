@import "../../../styles/config";

.dateButton {
  background: #ffffff;
  border-radius: 1px;
  border: 1px solid $brand;
  display: flex;
  color: $brand;
  align-items: center;
  justify-content: center;
  padding: 8px 18px;
  border-radius: 20px;
  cursor: pointer;

  @media (max-width: $breakpoint-mobile) {
    padding: 8px 12px;
  }
}

.dateText {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  margin-right: 12px;

  @media (max-width: $breakpoint-mobile) {
    font-size: 12px;
    margin-right: 4px;
  }
}

.container {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 30px;
  width: 380px;
}
