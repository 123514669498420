@import "../../../styles/config";

.icon {
  width: 16px;
  height: 16px;
  border: 1px solid $wolf-200;
  border-radius: 4px;
}

.popoverWrapper {
  font-family: Lato;

  :global(.sketch-picker) {
    box-shadow: none !important;
  }
}

.modalWrapper {
  box-sizing: border-box;
  font-family: Lato;
}
