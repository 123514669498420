@import "../../../styles/config";

.root {
  overflow: hidden;
  :global(.ScModal-content) {
    flex: 1;
    padding: 0 !important;
    background: white;
  }
  &:global(.entering) {
    :global(.ScModal-content) {
      animation-name: none;
    }
  }
  &:global(.exiting) {
    :global(.ScModal-content) {
      animation-name: none;
    }
  }
  &.left {
    align-items: flex-start;
    > div {
      top: 0;
      bottom: 0;
    }
    &:global(.entering) {
      :global(.ScModal-content) {
        animation-name: left-entering;
      }
    }
    &:global(.exiting) {
      :global(.ScModal-content) {
        animation-name: left-exiting;
      }
    }
  }
  &.right {
    align-items: flex-end;
    > div {
      top: 0;
      bottom: 0;
    }
    &:global(.entering) {
      :global(.ScModal-content) {
        animation-name: right-entering;
      }
    }
    &:global(.exiting) {
      :global(.ScModal-content) {
        animation-name: right-exiting;
      }
    }
  }
  &.top {
    flex-direction: row;
    align-items: flex-start;
    > div {
      left: 0;
      right: 0;
    }
    &:global(.entering) {
      :global(.ScModal-content) {
        animation-name: top-entering;
      }
    }
    &:global(.exiting) {
      :global(.ScModal-content) {
        animation-name: top-exiting;
      }
    }
  }
  &.bottom {
    flex-direction: row;
    align-items: flex-end;
    > div {
      left: 0;
      right: 0;
    }
    &:global(.entering) {
      :global(.ScModal-content) {
        animation-name: bottom-entering;
      }
    }
    &:global(.exiting) {
      :global(.ScModal-content) {
        animation-name: bottom-exiting;
      }
    }
  }
}

.content {
  &.overflowAuto {
    overflow: auto;
    overflow-x: hidden;
  }
  &.overflowHidden {
    overflow: hidden;
  }
  &.overflowVisible {
    overflow: visible;
  }

  // scroll bar
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &:hover {
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
    }
    &::-webkit-scrollbar-thumb {
      background: #ccc;
    }
  }
}

@keyframes left-entering {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes left-exiting {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes right-entering {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes right-exiting {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes top-entering {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes top-exiting {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes bottom-entering {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bottom-exiting {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
