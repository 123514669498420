@import "../../../styles/config";

.root {
  display: inline-flex;
  align-items: center;
  background: $wolf-200;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  border-radius: 2px;

  &.small {
    min-height: 20px;
    font-size: 10px;
    line-height: 14px;
    &:has(button) {
      .body {
        padding: 0 2px 0 6px;
      }
    }

    .body {
      padding: 0 6px 0 6px;
    }

    .button {
      width: 20px;
      height: 20px;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  &.medium {
    min-height: 24px;
    font-size: 12px;
    line-height: 14px;
    &:has(button) {
      .body {
        padding: 0 4px 0 8px;
      }
    }

    .body {
      padding: 0 8px 0 8px;
    }

    .button {
      width: 24px;
      height: 24px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.body {
  flex: 1;
}

.actions {
  position: relative;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    background-color: #ffbdad;
    color: #de350b;
  }

  svg {
    fill: $wolf-200;
  }
}
