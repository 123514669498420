@import "../../../styles/config";

.root {
  display: flex;
  align-items: center;
  gap: 8px;
}

.today {
  margin-right: 16px;
}

.icon {
  display: flex;
  align-items: center;
}

.date {
  margin-left: 4px;
  white-space: nowrap;
  @media (min-width: $breakpoint-sm) {
    margin-left: 16px;
  }
}

.button {
  display: flex;
  align-items: center;
  height: 26px;
  border: 0;
  background: transparent;
  cursor: pointer;
  @include body-lg;
  @media (min-width: $breakpoint-sm) {
    @include heading-5;
  }
}

.buttonIcon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  @media (min-width: $breakpoint-sm) {
    margin-left: 12px;
  }
}
