@import "../../../styles/config";

.panelparent {
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #dae0e6;
  font-family: Lato;
  font-size: 12px;
  display: flex;
  padding: 24px 20px;
  max-width: 400px;
}

.previewgreen {
  position: absolute;
  bottom: 22px;
  left: 18px;
  right: 18px;
}

.icon {
  margin-right: 16px;
}

.contenttop {
  line-height: 1.8;
  margin-top: -5px;
}

.contentlink {
  display: flex;
  align-items: center;
  margin-top: 16px;
  text-decoration: none;
  color: $brand-normal;
}

.contentlink svg {
  margin-left: 8px;
}
