@import "../../../styles/config";

.root {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  position: absolute;
  top: 50%;
  margin-top: -9px;
  right: 12px;
  width: 8px;
  height: 18px;
  vertical-align: middle;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;

  &.disabled {
    cursor: default;
    opacity: 0.4;
  }
}

.upIcon {
  position: relative;
  top: 1px;
  color: $wolf-400;
  &.active {
    color: $wolf-800;
  }
}

.downIcon {
  position: relative;
  top: -1px;
  color: $wolf-400;
  &.active {
    color: $wolf-800;
  }
}
