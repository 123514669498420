@import "../../../styles/config";

.container {
  @include reset;
  font-size: 18px;
  font-family: Lato;
  line-height: 18px;
  width: $grid-unit-60;
  height: $grid-unit-60;
  border-radius: $grid-unit-30;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $pacific-primary-100;
  color: $pacific-primary;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: $breakpoint-mobile) {
    font-size: 12px;
    line-height: 12px;
    width: $grid-unit-40;
    height: $grid-unit-40;
    border-radius: $grid-unit-20;
  }
}
