@import "../../../styles/config";

.root {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid $border;
  border-radius: 4px;
  font-family: Lato;
  background: $white;

  // placeholder
  [data-slate-placeholder="true"] {
    width: auto !important;
    opacity: 1 !important;
    padding-bottom: 3px;
    padding-right: 11px;
    color: $wolf-500;
  }

  // state
  &:hover {
    border-color: $pacific-primary-300;
  }
  &:focus-within {
    box-shadow: 0 0 0 3px $pacific-primary-100;
    border-color: $pacific-primary-600;
  }

  // disabled
  &.disabled {
    background: $wolf-100;
    border-color: $border-disabled;
    &:hover {
      box-shadow: none;
    }
    .icon {
      opacity: 0.4;
    }
    .text {
      color: $wolf-500;
    }
  }

  // error
  &.error {
    border-color: $wild-watermellon-300;
    &:hover {
      box-shadow: 0 0 0 3px rgba($wild-watermellon-600, 0.15);
    }
    &:focus-within {
      box-shadow: 0 0 0 3px rgba($wild-watermellon-600, 0.15);
    }
    &.disabled {
      &:hover {
        box-shadow: none;
      }
    }
  }

  // multi-line
  &.multi-line {
    flex-direction: column;

    .actions {
      padding: 8px;
    }
  }

  // single line
  &.single-line {
    flex-direction: row;
    align-items: center;
    min-height: 32px;
    padding: 2px 0;

    .content {
      > div[role="textbox"] {
        padding: 0 11px;
      }
    }

    .actions {
      padding: 0 8px;
    }
  }

  // toolbar-top
  &.toolbar-top {
    .content {
      > div[role="textbox"] {
        padding: 0px 11px 3px 11px;
      }
    }
  }

  // toolbar-bottom
  &.toolbar-bottom {
    .content {
      > div[role="textbox"] {
        padding: 3px 11px 0px 11px;
      }
    }
  }
}

.content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $wolf-400;
    &:hover {
      background: $wolf-500;
    }
  }
}

.actions {
  display: flex;
  justify-content: center;
}

// content elements

.content {
  font-size: 14px;
  * {
    line-height: 1.8;
  }
  h1 {
    margin: 0;
    font-weight: normal;
    font-size: 26px;
  }
  h2 {
    margin: 0;
    font-weight: normal;
    font-size: 22px;
  }
  p {
    margin: 0;
  }
  ul {
    margin: 0;
  }
  ol {
    margin: 0;
  }
  a {
    color: $pacific-primary-600-default;
    cursor: text !important;
    &:hover {
      text-decoration: underline;
    }
  }
}
