@import "../../../styles/config";

.root {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-template-areas:
    "bullet body"
    "bullet coach";
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  .bullet {
    grid-area: bullet;
    align-self: center;
    width: 8px;
    height: 8px;
    margin: 0 12px 0 4px;
    border-width: 1px;
    border-style: solid;
    border-color: #e5eaef;
    border-radius: 999px;
  }

  .body {
    grid-area: body;
    display: flex;
  }

  .time {
    padding-right: 8px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    color: #2a3540;
    text-transform: lowercase;
  }

  .title {
    font-family: Lato;
    font-size: 12px;
    font-weight: 600;
    color: #2a3540;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .coach {
    grid-area: coach;
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    color: #60717d;
  }

  .multiDayTitle {
    padding: 0 8px;
    font-family: Lato;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    line-height: 20px;
  }

  &.invertTextColor {
    .multiDayTitle {
      color: $wolf-800;
    }
  }
}
