.btn {
  width: 262px;
  height: 54px;
  background: #ffffff;
  border: 1px solid #0063ff;
  border-radius: 4px;
}

.legend {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 160% */
  padding: 6px 0 12px 0;
  display: flex;
  align-items: center;

  /* wolf-800 */

  color: #2a3540;
}

.legendWhite {
  composes: legend;
  color: white;
}
