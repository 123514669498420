@import "../../../styles/config";

$animation-duration: 200ms;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  z-index: $modal-z-index;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  &.entering {
    animation-name: container-entering;
    animation-fill-mode: forwards;
    animation-duration: $animation-duration;
  }
  &.exiting {
    animation-name: container-exiting;
    animation-fill-mode: forwards;
    animation-duration: $animation-duration;
  }
  &.hideBackdrop {
    background-color: initial;
    pointer-events: none;
  }
  &.fullSize {
    .content {
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }
  &.top {
    justify-content: flex-start;
  }
  &.bottom {
    justify-content: flex-end;
  }

  .card {
    width: 400px;
    max-width: 85vw;
    display: flex;
    flex-direction: column;

    @media (max-width: $breakpoint-mobile) {
      height: 100vh;
      height: 100svh;
      height: 100dvh;
      max-height: -webkit-fill-available;
      width: 100vw;
      max-width: none;
      margin: -$grid-unit-20;
      border-radius: 0;
    }

    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }

  .headerCloseButton {
    margin-top: 1px;
  }

  .contentContainer {
    position: relative;
    overflow: hidden;

    @media (max-width: $breakpoint-mobile) {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-height: none;
    }

    .scrollableContent {
      overflow-x: hidden;
      max-height: 65vh;
      padding-bottom: $grid-unit-30;

      @media (max-width: $breakpoint-mobile) {
        flex: 1;
        max-height: inherit;
      }
    }

    .hideDivider {
      opacity: 0;
    }
  }
}

.content {
  position: fixed;
  padding: $grid-unit-20;
  pointer-events: visible;
  max-width: 100%;
  max-height: 100%;
  &.entering {
    animation-name: content-entering;
    animation-fill-mode: forwards;
    animation-duration: $animation-duration;
  }
  &.exiting {
    animation-name: content-exiting;
    animation-fill-mode: forwards;
    animation-duration: $animation-duration;
  }
  &.disableContentPadding {
    padding: 0;
  }
}

@keyframes container-entering {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes container-exiting {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes content-entering {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes content-exiting {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
