$vertical-margin: 40px;
$header-height: 67px;
$modal-padding: 16px;

.root {
  margin-top: $vertical-margin;
  padding-bottom: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.15);

  .header {
    padding: 20px 24px;
    &.none {
      padding: 0;
    }
    &.small {
      padding: 12px;
    }
    &.medium {
      padding: 20px 24px;
    }
  }

  .body {
    padding: 24px 24px 14px 24px;
    max-height: calc(
      100vh - $header-height - $vertical-margin * 2 - $modal-padding * 2 - 10px
    );
    overflow: auto;
    &.none {
      padding: 0 0 10px 0;
    }
    &.small {
      padding: 12px 12px 2px 12px;
    }
    &.medium {
      padding: 24px 24px 14px 24px;
    }
  }
}
