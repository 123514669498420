@import "../../../styles/config";

.body-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 395px;
  width: 100%;

  .upload-text {
    @include body-lg;
    margin-top: $grid-unit-40;
    text-align: center;
    color: $wolf-500;
  }

  .upload-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $grid-unit-20;
    margin-bottom: $grid-unit-60;
  }

  .upload-file-accepts {
    @include body-md;
    margin-top: $grid-unit-40;
    margin-bottom: $grid-unit-20;
    color: $wolf-500;
  }
}
