@import "../../../styles/config";

.container {
  width: 375px;
  background: $white;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.range-arrow {
  @include icon-size(16px);
  color: $wolf-500;
  margin: 0 $grid-unit-20;
}

.date-input-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $grid-unit-075 0 $grid-unit-20;
}

.date-input {
  @include body-lg;
  padding: $grid-unit-075 $grid-unit-15;
  border: 1px solid $border;
  border-radius: 4px;
  flex: 1;

  &.selectable {
    cursor: pointer;
  }
}

.tab-content {
  padding: $grid-unit-20;
}

.presets {
  margin-bottom: $grid-unit-30;
}

// PresetItem
.preset {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $grid-unit-20;
  cursor: pointer;

  &:hover {
    .preset-label {
      transition: all 120ms ease-in-out;
      color: $wolf-800;
    }
    .preset-checkbox {
      transition: all 120ms ease-in-out;
      border-color: $brand;
    }
  }

  &.selected {
    .preset-label {
      color: $wolf-800;
    }
    .preset-checkbox {
      background-color: $brand;
      border-color: $brand;
    }
  }
}

.preset-label {
  @include heading-5;
  color: $wolf-500;
}

.preset-checkbox {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 1px solid $border;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    @include icon-size(16px);
    color: $white;
  }
}
