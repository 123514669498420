@import "../../../styles/config";

.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  position: relative;
  width: 100%;
  border: 1px solid #dae0e6;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;

  select {
    background-color: transparent;
    border: none;
    margin: 0;
    width: 100%;
    font-family: Lato;
    font-size: 14px;
    cursor: inherit;
    padding: $grid-unit $grid-unit-20;
    z-index: 1;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
  }

  svg {
    position: absolute;
    right: $grid-unit;
    top: 6px;
    transform: scale(0.7);
  }
}

.select {
  position: relative;
  flex: 1;
  display: flex;
  border-right: 1px solid #dae0e6;

  &:last-of-type {
    border-right: none;
  }
}

.select-placeholder {
  @include heading-5;
  line-height: 1;
  color: $wolf-500;
  position: absolute;
  top: $grid-unit;
  left: $grid-unit-20;
}
