.text {
  padding: 2em;
  font-family: Lato;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375em;
  letter-spacing: 0px;
  text-align: left;
}

.button {
  display: flex;
  align-items: center;
  margin: 1em 0.5em 0.5em 1.3125em;
}

.separator {
  width: 100%;
  border-bottom: solid 1px;
  position: relative;
  margin: 1.2rem 0;
  border-color: #b8b8b8;
}

.legend {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 160% */
  padding: 6px 0 12px 0;
  display: flex;
  align-items: center;

  /* wolf-800 */

  color: #2a3540;
}
