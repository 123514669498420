@import "../../../styles/config";

.container {
  position: relative;
  width: fit-content;
}

.target {
  z-index: 0;
}

.popup {
  position: absolute;
  z-index: 3;
  max-width: calc(100vw - $grid-unit-40);

  &.top-left-inside,
  &.top-left-outside,
  &.top-center,
  &.top-right-inside,
  &.top-right-outside {
    top: unset;
    bottom: 100%;
  }

  &.bottom-left-inside,
  &.bottom-left-outside,
  &.bottom-center,
  &.bottom-right-inside &.bottom-right-outside {
    top: 100%;
    bottom: unset;
  }

  &.top-left-outside,
  &.center-left,
  &.bottom-left-outside {
    left: unset;
    right: 100%;
  }

  &.top-left-inside,
  &.bottom-left-inside {
    left: 0px;
    right: unset;
  }

  &.top-right-inside,
  &.bottom-right-inside {
    left: unset;
    right: 0px;
  }

  &.top-right-outside,
  &.center-right,
  &.bottom-right-outside {
    left: 100%;
    right: unset;
  }

  &.center-left,
  &.center-right {
    top: unset;
    bottom: unset;
  }

  &.top-center,
  &.bottom-center {
    left: unset;
    right: unset;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animation {
  animation: fadeIn 0.25s ease-in-out 1 forwards;
}
