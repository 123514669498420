@import "../../../styles/config";

.root {
  display: flex;

  // size
  &.small {
    @include font-size-body-sm;
  }
  &.medium {
    @include font-size-body-lg;
  }
  &.large {
    @include font-size-heading-5;
  }
  &.jumbo {
    @include font-size-heading-5;
  }
}

.container {
  flex: 1;
  display: flex;
  align-items: stretch;
  position: relative;
  border: 1px solid $border;
  border-radius: 4px;
  overflow: hidden;
  font-family: Lato;
  background: $white;

  // state
  &:hover {
    border-color: $pacific-primary-300;
  }
  &:focus-within {
    box-shadow: 0 0 0 3px $pacific-primary-100;
    border-color: $pacific-primary-600;
  }

  // size
  &.small {
    min-height: 24px;
    @include font-size-body-sm;
    .startIcon {
      padding: 0 0 0 4px;
    }
    .startText {
      padding: 0 8px;
    }
    .input {
      padding: 0 8px;
    }
    .endText {
      padding: 0 8px;
    }
    .endIcon {
      padding: 0 4px 0 0;
    }
  }
  &.medium {
    min-height: 32px;
    @include font-size-body-lg;
    .startIcon {
      padding: 0 0 0 8px;
    }
    .startText {
      padding: 0 10px;
    }
    .input {
      padding: 0 10px;
    }
    .endText {
      padding: 0 10px;
    }
    .endIcon {
      padding: 0 8px 0 0;
    }
  }
  &.large {
    min-height: 36px;
    @include font-size-heading-5;
    .startIcon {
      padding: 0 0 0 10px;
    }
    .startText {
      padding: 0 10px;
    }
    .input {
      padding: 0 12px;
    }
    .endText {
      padding: 0 10px;
    }
    .endIcon {
      padding: 0 10px 0 0;
    }
  }
  &.jumbo {
    min-height: 48px;
    @include font-size-heading-5;
    .icon {
      width: 24px;
      height: 24px;
    }
    .startIcon {
      padding: 0 0 0 12px;
    }
    .startText {
      padding: 0 12px;
    }
    .input {
      padding: 0 12px;
    }
    .endText {
      padding: 0 12px;
    }
    .endIcon {
      padding: 0 12px 0 0;
    }
  }

  // disabled
  &.disabled {
    background: $wolf-100;
    border-color: $border-disabled;
    &:hover {
      box-shadow: none;
    }
    .icon {
      opacity: 0.4;
    }
    .text {
      color: $wolf-500;
    }
  }

  // error
  &.error {
    border-color: $wild-watermellon-300;
    &:hover {
      box-shadow: 0 0 0 3px rgba($wild-watermellon-600, 0.15);
    }
    &:focus-within {
      box-shadow: 0 0 0 3px rgba($wild-watermellon-600, 0.15);
    }
    &.disabled {
      &:hover {
        box-shadow: none;
      }
    }
  }

  // rounded
  &.rounded {
    &.small {
      border-radius: 12px;
    }
    &.medium {
      border-radius: 16px;
    }
    &.large {
      border-radius: 18px;
    }
    &.jumbo {
      border-radius: 24px;
    }
  }

  // no radius
  &.noRadiusLeft {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  &.noRadiusRight {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.icon {
  width: 16px;
  height: 16px;
}

.text {
  font-family: Lato;
  color: $wolf-800;
}

.outer-text {
  font-family: Lato;
  color: $wolf-800;
}

.startOuterText {
  display: flex;
  align-items: center;
  padding-right: 12px;
}

.startIcon {
  display: flex;
  align-self: center;
  * {
    fill: $wolf-500 !important;
  }
}

.startText {
  display: flex;
  align-items: center;
  border-right: 1px solid $border;
}

.input {
  align-self: center;
  flex: 1;
  height: 100%;
}

.endText {
  display: flex;
  align-items: center;
  border-left: 1px solid $border;
}

.endIcon {
  display: flex;
  align-self: center;
  * {
    fill: $wolf-800 !important;
  }
}

.endOuterText {
  display: flex;
  align-items: center;
  padding-left: 12px;
}
