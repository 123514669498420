@import "../../../styles/config";

.popoverWrapper {
  width: 300px;
}

.modalWrapper {
  box-sizing: border-box;
  min-height: 400px;
  padding: $grid-unit-30;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 16px 32px rgb(0 0 0 / 15%);
}
