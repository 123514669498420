@import "../../../styles/config";

.content-parent {
  background-color: $white;
  border-radius: 10px;
  padding: 24px;
  width: 341px;
  max-width: 100%;
  font-family: Lato;
  box-shadow: 0px 8px 32px rgb(0 0 0 / 35%);
}

.content-body {
  @include font-size-body-lg;
  line-height: 1.5;
  margin-top: 24px;
  margin-bottom: 28px;
}

.content-title {
  @include font-size-heading-4;
  line-height: normal;
  color: $wolf-800;
  font-weight: normal;
}

.content-buttons {
  margin-top: 24px;
  display: flex;
  gap: 16px;
}

.loading {
  display: flex;
  justify-content: center;
}
