@import "../../../styles/config";

.container {
  @include reset;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: opacity 120ms ease-in-out;

  /* Types */
  &.primary {
    background-color: $brand;
    color: white;
    border: 1px solid $brand;

    .icon {
      color: $white;
    }
  }

  &.secondary {
    background-color: $white;
    color: $brand;
    border: 1px solid $brand;

    .icon {
      color: $brand;
    }
  }

  /* Sizes */
  &.jumbo {
    width: $grid-unit-60;
    height: $grid-unit-60;
    border-radius: $grid-unit-30;

    .icon {
      @include icon-size($grid-unit-30);
    }
  }

  &.large {
    width: $grid-unit-40;
    height: $grid-unit-40;
    border-radius: $grid-unit-20;

    .icon {
      @include icon-size($grid-unit-20);
    }
  }

  /* States */
  &:disabled {
    pointer-events: none;
    background-color: $white;
    border-color: $disabled;
    color: $disabled;

    .icon {
      color: $disabled;
    }
  }

  &:hover {
    transition: opacity 120ms ease-in-out;
    opacity: 0.75;
  }
}
