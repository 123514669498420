@import "../../../styles/config";

.root {
  .input {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    padding-right: 32px !important;
    color: $black;
    border: 0;
    appearance: none;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    background: transparent;
    outline: 0;

    // disabled
    &:disabled {
      color: $wolf-500;
    }

    // size
    &.small {
      padding: 0 8px;
    }
    &.medium {
      padding: 0 10px;
    }
    &.large {
      padding: 0 12px;
    }
    &.jumbo {
      padding: 0 12px;
    }
  }
  .placeholder {
    display: flex;
    align-items: center;
    position: absolute;
    inset: 0;
    padding: 0 32px 0 12px;
    pointer-events: none;
    span {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      color: $wolf-500;
    }
    &.hide {
      position: absolute;
      opacity: 0;
    }
  }
}
