@import "../../../styles/config";

.root {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 0;
}

.input {
  position: absolute;
  opacity: 0;
  &:hover {
    + .control {
      border-color: $pacific-primary-600;
    }
  }
  &:active {
    + .control {
      border-color: $pacific-primary-700;
    }
  }
  &:focus-within {
    + .control {
      box-shadow: 0 0 0 3px $pacific-primary-100;
      border-color: $pacific-primary-600;
    }
  }
  &:checked {
    + .control {
      border-color: $brand;
      border-width: 5px;
    }
    &:active {
      + .control {
        background-color: $wolf-100;
      }
    }
  }
  &:disabled {
    &:hover {
      + .control {
        box-shadow: none;
      }
    }
    & ~ .control {
      border-color: $wolf-500;
      cursor: default;
      .checkmark {
        display: block;
      }
    }
    & ~ .label {
      cursor: default;
      color: $wolf-500;
    }
  }
}

.control {
  box-sizing: border-box;
  min-width: 16px;
  min-height: 16px;
  position: relative;
  border: 1px solid $wolf-500;
  border-radius: 999px;
  background-color: $white;
  cursor: pointer;
}

.label {
  @include body-lg;
  line-height: 16px !important;
  margin-left: 8px;
  cursor: pointer;
  user-select: none;
}
