@import "../../../styles/config";

.container {
  border: 1px solid #dae0e6;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: $white;
  flex: 1;
  overflow: hidden;
  width: 100%;
}

.input {
  @include heading-5;
  color: $black;
  padding: $grid-unit-025 $grid-unit-15;
  width: 100%;
}

.select-parent {
  display: flex;
  align-items: center;
  position: relative;
  border-right: 1px solid #dae0e6;
  cursor: pointer;

  select {
    @include body-lg;
    appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    cursor: pointer;
    line-height: inherit;
    position: relative;
    min-width: $grid-unit-80;
    padding: $grid-unit $grid-unit-15;
    z-index: 1;
  }

  svg {
    position: absolute;
    right: 8px;
    transform: scale(0.7);
  }
}
