@import "../../../styles/config";

.root {
  display: flex;
  flex-direction: column;
  position: relative;

  // full height
  &.fullHeight {
    height: 100%;
  }
}

.header {
  flex-shrink: 0;
  position: relative;
}

.body {
  flex: 1;
  position: relative;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $wolf-300;
    &:hover {
      background: $wolf-400;
    }
  }
}
