@import "../../../styles/config";

.root {
  display: flex;
  align-items: flex-start;
  width: fit-content;
  max-width: 100%;
  margin-bottom: 0;

  .input {
    position: absolute;
    opacity: 0;
    &:hover {
      + .control {
        border-color: $pacific-primary-600;
      }
    }
    &:active {
      + .control {
        border-color: $pacific-primary-600;
        background-color: $wolf-100;
      }
    }
    &:focus-within {
      + .control {
        box-shadow: 0 0 0 3px $pacific-primary-100;
        border-color: $pacific-primary-600;
      }
    }
    &:checked {
      + .control {
        background-color: $brand;
        border-color: $brand;
        .checkmark {
          display: block;
        }
      }
      &:hover {
        + .control {
          border-color: $pacific-primary-500;
          background-color: $pacific-primary-500;
        }
      }
      &:active {
        + .control {
          border-color: $pacific-primary-700;
          background-color: $pacific-primary-700;
        }
      }
    }
    &:disabled {
      &:hover {
        + .control {
          box-shadow: none;
        }
      }
      &:active {
        + .control {
          background-color: $white;
        }
      }
      & ~ .control {
        border-color: $wolf-300;
        cursor: default;
        .checkmark {
          display: block;
        }
      }
      & ~ .label {
        cursor: default;
        color: $wolf-500;
      }
    }
    &:checked:disabled {
      + .control {
        background: $wolf-500;
        border-color: $wolf-500;
        .checkmark {
          color: $white;
        }
      }
    }
  }
  .control {
    margin-top: $grid-unit-025;
    min-width: 16px;
    min-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid $wolf-500;
    border-radius: 5px;
    background-color: $white;

    cursor: pointer;
    .checkmark {
      @include icon-size(10px);
      display: none;
      color: $white;
    }
  }
  .label {
    @include body-lg;
    margin-left: $grid-unit-15;
    cursor: pointer;
    user-select: none;
    // Ellipsis when text is to long
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.error {
    .label {
      color: $wild-watermellon-600 !important;
    }
    .control {
      border: 1px solid $wild-watermellon-600;
    }
  }

  // size
  &.small {
    .label {
      @include font-size-body-sm;
      margin-left: $grid-unit-10;
    }
  }
  &.medium {
    .label {
      @include font-size-body-lg;
    }
  }
  &.large {
    .label {
      @include font-size-heading-5;
    }
  }
  &.jumbo {
    .label {
      @include font-size-heading-5;
    }
  }
}
