html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

#__next {
  height: 100%;
}

@keyframes rotate {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
