@import "../../../styles/config";

.input {
  color: $black;
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background: #fff;
  &:disabled {
    color: $black;
    opacity: 1;
  }
  &::placeholder {
    color: $wolf-500;
  }
  &.disabled {
    background: transparent;
    color: $wolf-500;
  }
}
