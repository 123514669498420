.parent {
  border: 1px solid #cccccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-family: Lato;
  background-color: #ffffff;
  flex: 1;
  overflow: hidden;
  max-width: 400px;
}

.showcase-parent {
  display: flex;
  justify-content: space-between;
}

.showcase-spacer {
  width: 40px;
}

.input {
  font-family: Lato;
  resize: none;
  border: none;
  padding: 2px 10px;
  background: none;
  flex: 1;
  outline: none;
}

.data-label {
  padding: 6px 12px;
  font-size: 14px;
  color: #484848;
  border-left: 1px solid #cccccc;
}
