@import "../../../styles/config";

/* PanelHeaderHeader */
.header {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $grid-unit-20;
  border-bottom: 1px solid $wolf-300;

  @media (max-width: $breakpoint-mobile) {
    padding: $grid-unit-15 $grid-unit-20;
    margin-bottom: $grid-unit;
  }

  &.sticky {
    position: sticky;
    background: #fff;
    z-index: 100;
  }
}

.close {
  margin-right: 16px !important;
}

.title {
  @include heading-4;
  flex: 1;
  color: $wolf-800;
  margin-right: $grid-unit-30;

  @media (max-width: $breakpoint-mobile) {
    @include font-size-heading-5;
  }

  &.center {
    text-align: center;
  }
}

.action-menu {
  display: flex;
  gap: $grid-unit;
  align-items: center;
}
