@import "../../../styles/config";

.container {
  border-radius: 10px;
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  position: relative;
  background-color: $white;

  > *:last-child:not(.action-menu) {
    padding-bottom: 32px;
  }
}

/* Main Content */
.main-content {
  padding: $grid-unit-40;
  background-color: $brand-normal;

  @media (max-width: $breakpoint-mobile) {
    padding: $grid-unit-20 $grid-unit-30;
  }
}

.close-icon {
  position: absolute;
  top: 32px;
  right: 32px;
  color: white;
}

.bottom-info {
  padding: $grid-unit-30;

  @media (max-width: $breakpoint-mobile) {
    padding: $grid-unit-20 $grid-unit-30;
  }
}

.link-holder {
  background-color: #ffffff;
  @include body-lg;
  padding: $grid-unit-20 $grid-unit-30;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  flex-wrap: wrap;
}

.link {
  margin-right: $grid-unit-10;
  word-wrap: break-word;
  line-height: 1.7;
  max-width: 80%;
}

.top-bar {
  background-color: $pacific-primary-600;
  display: flex;
  align-items: center;
  @include heading-4;
  color: white;
  padding: $grid-unit-40;
  padding-bottom: 0px;
  justify-content: space-between;

  @media (max-width: $breakpoint-mobile) {
    padding: $grid-unit-20 $grid-unit-30;
    padding-bottom: 0px;
  }
}

.title {
  @include heading-1;
  color: $white;
  margin-bottom: $grid-unit-30;
  @include typography-sizes;

  @media (max-width: $breakpoint-mobile) {
    margin-bottom: $grid-unit-20;
  }
}

/* Highlight */
.highlight {
  display: flex;
  align-items: center;
  margin-bottom: $grid-unit-30;

  svg {
    @include icon-size(20px);
    color: $white;
    margin-right: $grid-unit-30;

    @media (max-width: $breakpoint-mobile) {
      @include icon-size(16px);
      margin-right: $grid-unit-20;
    }
  }

  p {
    @include body-lg;
    color: $white;
    font-weight: bold;

    @media (max-width: $breakpoint-mobile) {
      @include font-size-body-md;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    margin-bottom: $grid-unit-20;
  }
}

/* Attribute List */
.attribute-list {
  max-height: 60vh;
  overflow: auto;

  @media (max-width: $breakpoint-mobile) {
    max-height: 50vh;
  }
}

/* Attribute */
.attribute {
  display: flex;
  justify-content: space-between;
  padding: $grid-unit-30;
  align-items: center;
  border-bottom: 1px solid $wolf-200;
}

.attribute-field,
.attribute-value {
  @include body-md;
}

/* Action Menu */
.action-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $grid-unit-70;
  padding: $grid-unit-70 0;
  background-color: $white;
  box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.03);

  @media (max-width: $breakpoint-mobile) {
    gap: $grid-unit-50;
    padding: $grid-unit-40 0;
  }
}
