@import "../../../styles/config";

.container {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: $grid-unit-20 $grid-unit-30;
  background-color: $white;
  border: 1px solid $border;
  border-radius: 10px;
  z-index: $toast-z-index;

  &.top {
    align-items: flex-start;
  }

  &.center {
    align-items: center;
  }

  &.bottom {
    align-items: flex-end;
  }
}

.icon {
  @include icon-size(24px);
  margin-right: $grid-unit-20;
  color: $black;
  &.warning {
    * {
      fill: $golden-rod;
    }
  }
  &.error {
    * {
      fill: $wild-watermellon;
    }
  }
  &.success {
    * {
      fill: $sea-foam-600;
    }
  }
}

.title {
  @include body-lg;
  display: block;
  margin-bottom: 4px;
}

.message {
  @include body-md;
  color: $wolf-600;
}
