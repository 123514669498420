@import "../../../styles/config";

.container {
  width: fit-content;
  display: flex;

  *:focus {
    outline: none;
  }

  .input-day-container {
    position: relative;

    .input-day {
      @include heading-5;
      height: 36px;
      width: 100px;
      background: $white;
      border: 1px solid $wolf-300;
      border-right: none;
      box-sizing: border-box;
      border-radius: $grid-unit-05 0px 0px $grid-unit-05;
      padding: $grid-unit-075 $grid-unit-20;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: $grid-unit-30;
      cursor: pointer;

      &::placeholder {
        color: $wolf-500;
      }
    }

    svg {
      position: absolute;
      height: 100%;
      right: $grid-unit-10;
      color: $wolf-800;
      transform: scale(0.7);
      pointer-events: none;
    }
  }

  .input-time-container {
    display: flex;
    height: 36px;
    background: $white;
    border-radius: 0px $grid-unit-05 $grid-unit-05 0px;
    border: 1px solid $wolf-300;
    width: 217px;

    .input-time-content {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input {
      @include heading-5;
      border: none;
      width: 100px;
      height: 34px;
      background: transparent;
      text-align: center;
      cursor: pointer;

      &::placeholder {
        color: $wolf-500;
      }
    }

    .svg-box {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        @include icon-size(16px);
        right: $grid-unit-10;
        color: $wolf-500;
      }
    }
  }

  .clear-all-container {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    outline: none;
    border: none;
    padding-left: $grid-unit-15;

    svg {
      @include icon-size(16px);
      color: $wolf-800;
      cursor: pointer;
    }
  }

  // Overwrite styles for embedded <Select> component
  [class^="Select"] {
    border-right: 0px;
    border-radius: $grid-unit-05 0px 0px $grid-unit-05;
    height: 36px;
  }
}
