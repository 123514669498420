@import "../../../styles/config";

.root {
  > div {
    > div {
      padding: 0 !important;
    }
  }
}

.input {
  color: $black;
  width: 100%;
  height: 100%;
  padding: 6px 10px;
  border: 0;
  outline: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  resize: none;
  &:disabled {
    background: transparent;
    color: $wolf-500;
  }
  &::placeholder {
    color: $wolf-500;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $wolf-400;
    &:hover {
      background: $wolf-500;
    }
  }
}
