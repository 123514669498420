@import "../../../styles/config";

.container {
  width: 332px;
  max-width: calc(100vw - $grid-unit-80);
  display: flex;
  padding: 18px;
  background-color: $white;
  border-radius: 10px;
  border: 1px solid $wolf-300;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
}

.icon {
  width: 24px;
  height: 24px;

  &.success {
    color: $inch-worm-500;
  }

  &.error {
    color: $wild-watermellon-500;
  }

  &.warning {
    color: $golden-rod-500;
  }

  &.info {
    color: $sea-foam-500;
  }

  &.neutral {
    color: $wolf-800;
  }
}

.message {
  flex: 1;
  @include body-lg;
  color: $wolf-800;
  margin-left: 16px;
  margin-top: 1px;
  margin-bottom: 1px;
  word-break: break-word;
}

.closeButton {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-left: 16px;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
  }
}

.toast-wrapper {
  position: fixed;
  width: 100%;
  display: flex;
  bottom: 0;
  justify-content: center;
  opacity: 0;
  transform: translateY(100px);
  transition: all 200ms ease-out;
  z-index: $toast-z-index;

  &.visible {
    transition: all 200ms ease-out;
    opacity: 1;
    transform: translateY(-32px);
  }
}
