.appConfigurationContainer {
  display: grid;
  max-width: 80rem;
  grid-column-gap: 1em;
}

.item1 {
  grid-column-start: 1;
  grid-column-end: 3;
  background-color: #fff;
  border-radius: 0.5em;
}

.header {
  padding-top: 1em;
  padding-left: 1em;
}

@media only screen and (min-width: 600px) {
  .item2 {
    grid-column-start: 3;
    grid-column-end: 8;
    min-height: 900px;
  }
}

.body {
  padding: 0em;
}
