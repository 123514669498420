@import "../../../styles/config";

.root {
  position: relative;
  padding: 0 4px;

  &.small {
    display: flex;
    white-space: nowrap;
    align-items: center;
    .title {
      padding-right: 4px;
    }
    .time {
      font-size: 12px;
    }
    .coach {
      display: none;
    }
  }

  &.medium {
    .coach {
      display: none;
    }
  }

  &.invertTextColor {
    .title {
      color: $wolf-800;
    }

    .time {
      color: $wolf-800;
    }

    .coach {
      color: $wolf-800;
    }
  }
}

.title {
  font-family: Lato;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
  line-height: 18px;
}

.time {
  font-family: Lato;
  font-size: 11px;
  font-weight: 400;
  text-transform: lowercase;
  line-height: 18px;
}

.coach {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
