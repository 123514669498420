@import "../../../styles/config";

.container {
  list-style: none;
  padding-left: 0;
  margin: 0;

  li:first-child {
    margin-top: 0;
  }

  li {
    @include body-md;
    margin-top: 13px;
    display: flex;
    align-items: center;
    font-size: 12px;

    svg {
      height: 12px;
      color: $wolf-500;
    }
  }
}
