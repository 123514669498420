@import "../../../styles/config";

.container {
  background: white;
  font-family: Lato;
  font-style: normal;
  max-width: 390px;
}

.react-datepicker__navigation {
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: 8px;
  padding: 0;
  outline: 0;
  border-radius: 999px;
  &:hover {
    color: $pacific-primary-600;
  }
  &:focus {
    box-shadow: 0 0 2px 4px $pacific-primary-100;
  }
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.column {
  padding: 0.5em;
}

.label {
  //styleName: Body Medium;
  font-family: Lato;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25em;
  letter-spacing: 0px;
  text-align: left;
}

.date-input {
  background: none;
  border: 1px solid #cccccc;
  border-radius: 0.25em;
  width: 10em;
  padding: 0.3em;
}

.title {
  //styleName: Heading 4;
  font-family: Lato;
  font-size: 1.25em;
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.month-buttons {
  margin: auto 0 auto auto;
  // Using this to offset the padding added
  margin-right: -10px;
  display: flex;
  button {
    cursor: pointer;
  }
}

.day-names-table {
  display: table;
  width: 100%;
  table-layout: fixed;
  font-family: Lato;
  font-weight: 400;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  color: #cccccc;
}
.day-names-row {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
}

.day-name {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.25em;
  width: 2.25em;
  font-family: Lato;
  font-size: 12px;
}

.container {
  :global(.react-datepicker__day--selected) {
    background: $pacific-primary-100;
  }

  :global(.react-datepicker__month) {
    display: table;
    width: 100%;
    table-layout: fixed;
    font-weight: bold;
  }

  :global(.react-datepicker__week) {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
  }

  :global(.react-datepicker__day) {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 2.25em;
    width: 2.25em;
    border-radius: 50%;
    text-align: center;
    font-family: Lato;
    cursor: pointer;
    font-weight: normal;
    font-size: 12px;
    outline: 0;

    &:hover {
      background-color: $wolf-100;
    }

    &:focus {
      box-shadow: 0 0 2px 4px $pacific-primary-100;
      border: 1px solid $pacific-primary-600;
    }
  }

  :global(.react-datepicker__day--keyboard-selected) {
    box-shadow: 0 0 2px 4px $pacific-primary-100;
    border: 1px solid $pacific-primary-600;
  }

  :global(.react-datepicker__day--selected) {
    background-color: $pacific-primary-600 !important;
    cursor: pointer;
    color: #fff !important;
  }

  :global(.react-datepicker__day--outside-month) {
    color: #cccccc;
  }

  :global(.react-datepicker__day-names) {
    display: none;
  }

  :global(.react-datepicker__day--in-range) {
    background-color: #b9e1ff;
    color: $pacific-primary-600;
  }

  :global(.react-datepicker__day--in-selecting-range) {
    background-color: #b9e1ff;
    color: $pacific-primary-600;
  }

  :global(.react-datepicker__day--today) {
    background: $pacific-primary-100;
  }

  :global([role="alert"]) {
    display: none;
  }
}
