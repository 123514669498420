@import "../../../styles/config";

$small-size: 28px;
$medium-size: 36px;
$large-size: 48px;

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 999px;
  text-align: center;
  font-family: Lato;
  color: $pacific-primary-600;
  background: $pacific-primary-100;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  overflow: hidden;

  // image
  img {
    width: 100%;
    height: 100%;
    text-align: center;
    object-fit: cover;
  }

  // icon
  svg {
    width: 80%;
    height: 80%;
    text-align: center;
    object-fit: cover;
  }
}

// sizes

.small {
  width: $small-size;
  height: $small-size;
  line-height: $small-size - 2px;
  font-size: 12px;
}

.medium {
  width: $medium-size;
  height: $medium-size;
  line-height: $medium-size - 2px;
  font-size: 14px;
}

.large {
  width: $large-size;
  height: $large-size;
  line-height: $large-size - 2px;
  font-size: 20px;
}

// color

@include text-color("color-");

// background

@include background-color("bg-");

// border

@include border-color("border-");
