@import "../../../../styles/config";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    color: $wolf-800;
  }

  &:disabled {
    opacity: 0.48;
    cursor: default;
  }

  &.active {
    background: $wolf-200;
  }
}
