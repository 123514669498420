@import "../../../styles/config";

.container {
  width: fit-content;
  background: $white;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
  border-radius: $grid-unit-05;
  overflow: hidden;
  padding-top: 4px;
  padding-left: 4px;

  .columns-container {
    display: flex;

    .column {
      list-style: none;
      max-height: 260px;
      width: 100%;
      padding: 0;
      margin: 0;
      overflow-y: scroll;
      box-sizing: border-box;
      outline: none;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: transparent;
      }
      &:hover {
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-track {
          background: #fff;
        }
        &::-webkit-scrollbar-thumb {
          background: #ccc;
        }
      }
      &:focus {
        > [aria-selected="true"] {
          border-color: $pacific-primary-200;
        }
      }

      .item {
        border-radius: 4px;
        box-sizing: border-box;
        height: 33px;
        line-height: 27px;
        padding: 0 $grid-unit-20;
        padding-right: $grid-unit-25;
        font-family: Lato;
        font-weight: 400;
        color: $wolf-800;
        font-size: 14px;
        border: 2px solid transparent;
        text-align: center;
        cursor: pointer;

        &.selected {
          transition: background-color 0.3s;
          background: $pacific-primary-100;
        }

        &:hover {
          background-color: $pacific-primary-100;
        }
      }
    }
  }

  .bottom {
    display: flex;
    padding: $grid-unit-20 $grid-unit-25;
    justify-content: space-around;
    background-color: white;
  }
}
