@import "../../../styles/config";

@mixin statusColors {
  &.success {
    color: $inch-worm;

    svg {
      color: $inch-worm;
    }
  }

  &.error {
    color: $wild-watermellon;

    svg {
      color: $wild-watermellon;
    }
  }

  &.warning {
    color: $golden-rod;

    svg {
      color: $golden-rod;
    }
  }

  &.info {
    color: $sea-foam;

    svg {
      color: $sea-foam;
    }
  }

  &.neutral {
    color: $sea-foam;

    svg {
      color: $sea-foam;
    }
  }
}

@keyframes showBanner {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 168px;
    opacity: 1;
  }
}

@keyframes hideBanner {
  0% {
    height: 168px;
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: $wolf-800;

  &.showing {
    animation: showBanner 0.5s ease-in-out 1 forwards;
  }

  &.not-showing {
    animation: hideBanner 0.5s ease-in-out 1 forwards;
    pointer-events: none;
  }

  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 260px;
    height: 100%;

    .text-container {
      position: relative;

      .title {
        @include body-lg;
        font-weight: 700;
        color: $white;
      }

      .description {
        @include body-md;
        color: $white;
        margin-top: $grid-unit-15;
      }

      .icon-container {
        @include statusColors;
        position: absolute;
        top: 0;
        left: -$grid-unit-70;
      }
    }

    .buttons-container {
      display: flex;
      width: 100%;
      margin-top: $grid-unit-25;

      .resolve-button {
        border: none;
        @include statusColors;
      }

      .dismiss-button {
        color: $white;

        svg {
          color: $white;
        }
      }

      div {
        flex: 1;

        &:last-child {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
