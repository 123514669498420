@import "./colors";

$base-font-family: Lato;

/* Font size mixins */
@mixin font-size-jumbo {
  font-size: 46px;
  line-height: 54px;
}

@mixin font-size-heading-1 {
  font-size: 38px;
  line-height: 46px;
}

@mixin font-size-heading-2 {
  font-size: 30px;
  line-height: 38px;
}

@mixin font-size-heading-3 {
  font-size: 24px;
  line-height: 32px;
}

@mixin font-size-heading-4 {
  font-size: 20px;
  line-height: 28px;
}

@mixin font-size-heading-5 {
  font-size: 16px;
  line-height: 24px;
}

@mixin font-size-body-lg {
  font-size: 14px;
  line-height: 20px;
}

@mixin font-size-body-md {
  font-size: 12px;
  line-height: 18px;
}

@mixin font-size-body-sm {
  font-size: 11px;
  line-height: 16px;
}

/* 
  Typography element mixins 
  These mixins are intended to serve as base styles for text styles.
*/
@mixin jumbo {
  @include reset;
  @include font-size-jumbo;
  font-weight: 400;
  font-family: $base-font-family;
  color: $wolf-800;
}

@mixin heading-1 {
  @include reset;
  @include font-size-heading-1;
  font-weight: 400;
  font-family: $base-font-family;
  color: $wolf-800;
}

@mixin heading-2 {
  @include reset;
  @include font-size-heading-2;
  font-weight: 400;
  font-family: $base-font-family;
  color: $wolf-800;
}

@mixin heading-3 {
  @include reset;
  @include font-size-heading-3;
  font-weight: 400;
  font-family: $base-font-family;
  color: $wolf-800;
}

@mixin heading-4 {
  @include reset;
  @include font-size-heading-4;
  font-weight: 400;
  font-family: $base-font-family;
  color: $wolf-800;
}

@mixin heading-5 {
  @include reset;
  @include font-size-heading-5;
  font-weight: 400;
  font-family: $base-font-family;
  color: $wolf-800;
}

@mixin body-lg {
  @include reset;
  @include font-size-body-lg;
  font-weight: 400;
  font-family: $base-font-family;
  color: $wolf-800;
}

@mixin body-md {
  @include reset;
  @include font-size-body-md;
  font-weight: 400;
  font-family: $base-font-family;
  color: $wolf-800;
}

@mixin body-sm {
  @include reset;
  @include font-size-body-sm;
  font-weight: 400;
  font-family: $base-font-family;
  color: $wolf-800;
}

/* 
  This mixin can be included at the end of any text style for 
  a text element whose size can vary based on the context. The 
  enum "Typography" exists with a constant defined for each typography size.
  Example usage:
    // .scss
    .title {
      ...
      @include typography-sizes;
    }
    // .tsx
    <Component className={S('title', 'body-md')} />
    <Component className={S('title', Typography.Heading1} />
*/
@mixin typography-sizes {
  &.jumbo {
    @include font-size-jumbo;
    @media (max-width: $breakpoint-mobile) {
      @include font-size-heading-1;
    }
  }

  &.heading-1 {
    @include font-size-heading-1;
    @media (max-width: $breakpoint-mobile) {
      @include font-size-heading-2;
    }
  }

  &.heading-2 {
    @include font-size-heading-2;
    @media (max-width: $breakpoint-mobile) {
      @include font-size-heading-3;
    }
  }

  &.heading-3 {
    @include font-size-heading-3;
    @media (max-width: $breakpoint-mobile) {
      @include font-size-heading-4;
    }
  }

  &.heading-4 {
    @include font-size-heading-4;
    @media (max-width: $breakpoint-mobile) {
      @include font-size-heading-5;
    }
  }

  &.heading-5 {
    @include font-size-heading-5;
    @media (max-width: $breakpoint-mobile) {
      @include font-size-body-lg;
    }
  }

  &.body-lg {
    @include font-size-body-lg;
    @media (max-width: $breakpoint-mobile) {
      @include font-size-body-md;
    }
  }

  &.body-md {
    @include font-size-body-md;
    @media (max-width: $breakpoint-mobile) {
      @include font-size-body-sm;
    }
  }

  &.body-sm {
    @include font-size-body-sm;
  }
}
