@import "../../../styles/config";

.container {
  width: 164px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: $grid-unit;
  border-radius: 6px;
  border: 1px solid $border;
  background-color: $white;
  cursor: pointer;
  transition: all 100ms ease-in-out;

  &:hover,
  &.selected {
    border-color: $brand;

    .label {
      color: $brand;
    }
  }

  &.selected {
    outline: $pacific-primary-100 $grid-unit-05 solid;
  }

  &:disabled {
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.4;
  }
}

.label {
  @include body-md;
  margin-top: $grid-unit-05;
}

.graphic {
  max-height: $grid-unit-70;
  max-width: calc(100% - $grid-unit-20);
  object-fit: contain;
  object-position: center;
}
