@import "../../../styles/config";

.container {
  display: flex;
  flex-direction: column;

  &.fullHeight {
    height: 100%;
  }
}

.tabs-menu {
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    &:hover {
      background: $wolf-200;
    }
  }
  &.left {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
  &.right {
    justify-content: flex-end;
  }
  &.sticky {
    position: sticky;
    background: #fff;
    z-index: 100;
  }
  &.hideBottomBorder {
    border-bottom: 0;
  }
}

.tab-item {
  height: 100%;
  padding: 15px 22px;
  font-size: 14px;
  outline: none;
  resize: none;
  border: none;
  background: none;
  cursor: pointer;
  border-bottom: transparent 2px solid;
  font-family: Lato;
  white-space: nowrap;
  &:hover {
    color: $pacific-primary-400-default;
  }
  &:focus {
    color: $pacific-primary-600-default;
  }
}

.tab-item--selected {
  border-bottom: $brand-normal 2px solid;
  color: $brand-normal;
  pointer-events: none;
}

.tab-item:focus {
  outline: none;
}

.content {
  box-sizing: border-box;
  flex: 1;

  // scrollContent
  &.scrollContent {
    overflow-y: auto;
    overflow-x: hidden;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $wolf-300;
    &:hover {
      background: $wolf-400;
    }
  }
}

.panel {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip-path: inset(50%);
  white-space: nowrap;
  &.active {
    flex: 1;
    position: static;
    overflow: visible;
    width: auto;
    height: auto;
    clip-path: none;
    white-space: normal;
  }
}

// pills variant

.container {
  &.pills {
    .tabs-menu {
      gap: 8px;
      padding: 0 24px;
      border-bottom: 0;
    }
    .tab-item {
      height: 44px;
      padding: 0 10px;
      background: transparent;
      border-radius: 6px;
      border: 1px solid $wolf-300;
      color: $wolf-800;
      font-size: 14px;
      line-height: 20px;
    }
    .tab-item--selected {
      background: $pacific-primary-100;
      color: $pacific-primary-600;
      border: 1px solid $pacific-primary-100;
    }
  }
}

// buttons variant

.container {
  &.buttons {
    .tabs-menu {
      box-sizing: border-box;
      padding: 0;
      min-height: 32px;
      border-bottom: 0;
      border-radius: 0;
    }
    .tab-item {
      position: relative;
      flex: 1;
      min-height: 32px;
      padding: 0;
      border: 1px solid $wolf-200;
      border-left: 0;
      color: $wolf-800;
      &:hover {
        color: $pacific-primary-600;
      }
      &:focus {
        color: $pacific-primary-600;
      }
      &:first-child {
        border-left: 1px solid $wolf-200;
      }
    }
    .tab-item--selected {
      color: $pacific-primary-600;
      &::before {
        content: "";
        position: absolute;
        inset: -1px;
        border: 1px solid $pacific-primary-600;
        pointer-events: none;
      }
    }
  }
}
