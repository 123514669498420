.inputText {
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  height: 37px;
  width: 351px;
  border-radius: 4px;
  padding: 12px;
}

.text {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #2a3540;
  margin: 1px;
}
