.modal {
  display: none;
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.25);
}

.modal_content {
  background-color: white;
  position: absolute;
}

.close-button {
  display: flex;
  justify-content: end;
}

.container-cancel {
  display: flex;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}
