@import "../../../styles/config";

$borderRadius: 8px;
$headBackground: #fafafa;
$borderColor: $wolf-300;

.container {
  padding: 0;
  border: 1px solid $borderColor;
  border-radius: $borderRadius;
  background: $white;
}

.customHeader {
  position: relative;
}

.table {
  width: 100%;
  padding: 0;
  border: 0;
  border-collapse: collapse;

  &.fixedLayout {
    table-layout: fixed;
  }

  caption {
    @include screen-reader-only();
  }
}

.head {
  height: 52px;
  .row {
    &:first-child {
      .headCell {
        &:first-child {
          border-top-left-radius: $borderRadius;
        }
        &:last-child {
          border-top-right-radius: $borderRadius;
        }
      }
    }
  }
  &.hasCustomHeader {
    border-top: 1px solid $borderColor;
    .row {
      &:first-child {
        .headCell {
          &:first-child {
            border-top-left-radius: 0;
          }
          &:last-child {
            border-top-right-radius: 0;
          }
        }
      }
    }
  }
}

.headCell {
  position: relative;
  padding: 12px;
  background: $headBackground;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  &.isSortable {
    cursor: pointer;
    padding-right: 32px;
  }

  &.disabled {
    cursor: default;
  }

  &.showSeparator {
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -10px;
      right: 0;
      height: 20px;
      border-left: 1px solid $wolf-300;
    }
    &:last-child {
      &::after {
        content: none;
      }
    }
  }
}

.body {
  position: relative;
}

.row {
  position: relative;
  &:first-child {
    border-top: 0;
  }
  &.isRowDetail {
    .cell {
      padding-top: 0;
      border-top: 0;
    }
  }
  &.selected {
    .cell {
      background: $pacific-primary-100;
    }
  }
  &.hasOver {
    &:hover {
      .cell {
        background: $wolf-100;
      }
    }
  }
  &.hasClick {
    cursor: pointer;
  }
}

// border radios at the end of the table
.body,
.foot {
  &:last-child {
    .row {
      &:last-child {
        .cell {
          &:first-child {
            border-bottom-left-radius: $borderRadius;
          }
          &:last-child {
            border-bottom-right-radius: $borderRadius;
          }
        }
      }
    }
  }
}

.cell {
  padding: 18px 12px;
  background-color: $white;
  border-top: 1px solid $borderColor;
}

.foot {
  .row {
    .cell {
      padding: 12px;
      border-top: 1px solid $borderColor;
    }
    &:first-child {
      border-top: 0;
    }
  }
}

.customFooter {
  position: relative;
  border-top: 1px solid $borderColor;
}
