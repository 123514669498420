@import "../../../styles/config";

.container {
  @include body-lg;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $grid-unit-05 $grid-unit;
  background-color: $white;
  border: 1px solid $border;
  border-radius: 4px;
  cursor: pointer;
}

.placeholder {
  padding: $grid-unit-05;
}

.selections {
  width: min-content;
  display: flex;
  flex-wrap: wrap;
  gap: $grid-unit-05;
  flex: 1;
}

.caret {
  padding-left: $grid-unit;
  padding-right: $grid-unit-05;
  border-left: 1px solid $border;

  svg {
    display: block;
    color: #38404c;
  }
}

.clear-btn {
  display: block;
  margin: 0 $grid-unit;
  color: #38404c;
}

/* SelectedTag */
.selected-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $grid-unit-025 $grid-unit-05 $grid-unit-025 $grid-unit;
  background-color: #e6e6e6;
  width: fit-content;
  border-radius: 2px;

  span {
    margin-right: $grid-unit-05;
  }

  svg {
    display: block;
    color: #38404c;
  }
}
