@import "../../../styles/config";

.logo {
  width: 100%;
  object-fit: cover;
}

.description {
  @include body-md;
  margin-bottom: $grid-unit-15;
}

.container {
  margin: $grid-unit-10;
}

.content {
  margin-top: $grid-unit-30;
}

.content-title {
  @include body-sm;
}

.content-subtitle {
  @include body-lg;
}

.content-link {
  @include body-md;
  color: $pacific-primary-600;
}

.relative-popup {
  width: 100%;
}

.button-container {
  margin-top: $grid-unit-50;
  margin-bottom: $grid-unit-20;

  button {
    border-radius: 4px;
    white-space: pre-line;
  }

  .button-description {
    font-size: 12px;
  }

  .button-subtitle {
    @include body-sm;
    margin-top: $grid-unit-05;
    color: $wolf-800;
  }
}
