@import "../../../styles/config";

.root {
  padding: 12px 12px;
  @media (min-width: $breakpoint-sm) {
    padding: 16px 24px;
  }

  &.hasFilter {
    padding: 12px 12px 4px 12px;
    @media (min-width: $breakpoint-sm) {
      padding: 16px 24px 6px 24px;
    }
  }

  // add border only when having actions
  &:has(> .actions) {
    .dateRange {
      border-right: 1px solid $wolf-300;
    }
  }
}

.dateRange {
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
  vertical-align: top;
  padding-right: 16px;
  @media (min-width: $breakpoint-sm) {
    height: 34px;
    padding-right: 24px;
    margin-right: 24px;
  }
}

.actions {
  display: inline-flex;
  gap: 12px;
  vertical-align: top;
  padding-top: 2px;
  @media (min-width: $breakpoint-sm) {
    gap: 16px;
  }
  @media (min-width: $breakpoint-md) {
    padding-top: 0;
  }
}

.tags {
  display: block;
  margin-top: 10px;

  @media (min-width: $breakpoint-sm) {
    display: inline;
    margin-top: 16px;
    padding-left: 24px;
    vertical-align: top;
  }

  ul {
    display: inline;
    gap: 12px;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 8px;
    line-height: 16px;
    @media (min-width: $breakpoint-sm) {
      margin-right: 12px;
      margin-bottom: 16px;
      padding-top: 2px;
      line-height: 26px;
    }
  }
}
