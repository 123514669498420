.arrow {
  padding-right: 1rem;
  height: 1.5rem;
}

.text {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  color: #2a3540;
  margin: 0;
}

.bulletItem {
  display: flex;
  align-items: center;
}
