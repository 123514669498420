@import "../../../styles/config";

.root {
  display: flex;
  align-items: center;
  position: relative;
}

.content {
  flex: 1;
}

.title {
  position: relative;
}

.time {
  position: relative;
}

.menu {
  position: relative;
}

.button {
  padding: 6px;
}
