@import "../../../styles/config";

.root {
  :global(.PhoneInput) {
    display: flex;
    align-items: center;
    height: 100%;
    :global(.PhoneInputCountry) {
      display: flex;
      align-items: center;
      width: 68px;
      &::before {
        content: "";
        position: absolute;
        left: 68px;
        top: 0;
        bottom: 0;
        border-right: 1px solid $wolf-300;
      }
      select {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 80px;
        border: 0;
        opacity: 0;
        cursor: pointer;
      }
      :global(.PhoneInputCountryIcon) {
        display: flex;
        align-items: center;
        width: 24px;
        svg {
          color: $wolf-700;
        }
      }
      :global(.PhoneInputCountrySelectArrow) {
        width: 6px;
        height: 6px;
        margin: 0 8px 4px 11px;
        border-right: 1px solid $wolf-700;
        border-bottom: 1px solid $wolf-700;
        transform: rotateZ(45deg);
        pointer-events: none;
      }
    }
    input {
      flex: 1;
      color: $black;
      width: 100%;
      height: 100%;
      border: 0;
      outline: 0;
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
      &::placeholder {
        color: $wolf-500;
      }
    }
  }

  // error
  &.error {
    :global(.PhoneInput) {
      :global(.PhoneInputCountry) {
        &::before {
          border-right: 1px solid $wild-watermellon-300;
        }
      }
    }
  }

  // disabled
  &.disabled {
    :global(.PhoneInput) {
      pointer-events: none;
      :global(.PhoneInputCountry) {
        &::before {
          border-right: 1px solid $wolf-200;
        }
        :global(.PhoneInputCountryIcon) {
          opacity: 0.4;
        }
      }
      input {
        color: $wolf-500;
      }
    }
  }

  // size
  &.small {
    :global(.PhoneInput) {
      :global(.PhoneInputCountry) {
        width: 52px;
        &::before {
          left: 52px;
        }
        :global(.PhoneInputCountryIcon) {
          width: 18px;
        }
      }
    }
  }
  &.large {
  }
  &.jumbo {
    :global(.PhoneInput) {
      :global(.PhoneInputCountry) {
        width: 72px;
        &::before {
          left: 72px;
        }
        :global(.PhoneInputCountryIcon) {
          width: 32px;
        }
      }
    }
  }
}

.input {
  color: $black;
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  &:disabled {
    background: transparent;
    color: $wolf-500;
  }
  &::placeholder {
    color: $wolf-500;
  }
}
