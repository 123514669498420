@import "../../../styles/config";

.container {
  display: flex;
  justify-content: space-between;
  padding: $grid-unit-15 $grid-unit-20;
  border: 1px solid $border;
  border-radius: 5px;
  background-color: $white;
  cursor: pointer;
  width: 100%;
  text-align: left;
  transition: all 100ms ease-in-out;
  outline: transparent $grid-unit-05 solid;

  &:hover {
    outline-color: $pacific-primary-100;
    border-color: $brand;
  }

  &.selected {
    border-color: $brand;
    background-color: $brand;
    outline-color: transparent;

    .class-time,
    .class-name {
      color: $white;
    }
  }

  &.disabled {
    pointer-events: none;

    .class-time,
    .class-name {
      color: $wolf-500;
    }
  }
}

.class-time {
  @include body-lg;
  font-weight: 700;
}

.class-name {
  @include body-md;
  margin-top: $grid-unit-05;
}
