.container {
  display: grid;
  max-width: 75em;
  grid-column-gap: 1em;
}

.item1 {
  padding-top: 2em;
  padding-left: 1em;
  grid-column-start: 1;
  grid-column-end: 3;
  background-color: #fff;
  border-radius: 0.5em;
}

@media only screen and (min-width: 600px) {
  .item2 {
    grid-column-start: 3;
    grid-column-end: 8;
    min-height: 75em;
  }
}
