@import "../../../styles/config";

.root {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.pagination {
  padding: 16px;
}
