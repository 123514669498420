@import "../../../styles/config";

// CARD
.card {
  @include body-md;
  width: 100%;
  background: white;
  border-radius: 10px;
  border: 1px solid $wolf-300;
  overflow: hidden;

  .content,
  .footer,
  .cover,
  .header {
    padding: $grid-unit-20;
  }

  &.sm {
    width: 210px;

    .content,
    .footer,
    .cover,
    .header {
      padding: $grid-unit-15;
    }
  }

  &.md {
    width: 312px;

    .content,
    .footer,
    .cover,
    .header {
      padding: $grid-unit-20;
    }
  }

  &.lg {
    width: 400px;

    .content,
    .footer,
    .cover,
    .header {
      padding: $grid-unit-30;
    }
  }

  .footer {
    border-top: 1px solid $wolf-300;
  }
}

// HEADER
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $wolf-300;

  @media (max-width: $breakpoint-mobile) {
    padding: $grid-unit-15 $grid-unit-20;
    margin-bottom: $grid-unit;
  }
}

.title {
  @include heading-4;
  color: $wolf-800;
  margin-right: $grid-unit-30;

  @media (max-width: $breakpoint-mobile) {
    @include font-size-heading-5;
  }
}

.action-menu {
  display: flex;
  gap: $grid-unit;
  align-items: center;
}

// COVER
.cover {
  height: 156px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @include heading-4;
}
