@import "../../../styles/config";

.spinner {
  display: block;
  animation: full-rotation 1s infinite linear;
}

@keyframes full-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
