.container {
  display: grid;
  max-width: 75em;
  grid-column-gap: 1em;

  a {
    text-decoration: none;
  }
}

.item1 {
  padding-top: 2em;
  padding-left: 1em;
  grid-column-start: 1;
  grid-column-end: 3;
  background-color: #fff;
  border-radius: 0.5em;
}

.separator {
  border-bottom: solid 1px;
  margin: auto;
  padding: 10px 0;
  border-color: #b8b8b8;
  width: 100%;
}

.menuContainer {
  padding: 0 24px 0 25px;
}

.buttonGroup {
  display: flex;
  padding: 23px 0 3px 0;
  gap: 16px;
  button div svg path {
    fill: #0063ff;
  }
}

.learnMore {
  padding: 17px 0 0 0;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0063ff;

  /* identical to box height */
}

.learnMoreGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nextSteps {
  padding: 20px 0 15px 0;
}

.confirmTrainAccount {
  padding: 30px 0 15px 0;
}

.downloadSection {
  padding: 30px 0 5px 0;
}

@media only screen and (min-width: 600px) {
  .item2 {
    grid-column-start: 3;
    grid-column-end: 8;
    min-height: 75em;
  }
}
