@import "../../../styles/config";

.week-container {
  font-size: 16px;
  line-height: normal;
  font-family: "Lato";
  display: flex;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:active {
    + .week-day {
      border-color: $pacific-primary-600;
      background-color: $wolf-100;
    }
  }
  &:focus-within {
    + .week-day {
      box-shadow: 0 0 0 3px $pacific-primary-100;
      border-color: $pacific-primary-600;
    }
  }
}

.week-label {
  margin: 0px 5px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.week-day {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent;
  color: $pacific-primary-600;
  border: 1px solid $pacific-primary-600;
  cursor: pointer;

  &:hover {
    color: $pacific-primary-400;
    border: 1px solid $pacific-primary-400;
  }

  &.checked {
    background-color: $pacific-primary-600;
    color: #fff;
    border: 1px solid $pacific-primary-600;
  }
}

.error {
  border-color: $wild-watermellon-600;
  color: $wild-watermellon-600;
}
