@import "../../../styles/config";

.padding-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.vertical {
    height: 100%;
    width: 1px;

    .divider {
      height: 100%;
      width: 1px;
    }
  }
}

.divider {
  height: 1px;
  background-color: $dividers;
  width: 100%;
}

.center-element {
  padding: 0 $grid-unit;
}
