.container {
  width: 20rem;
  height: 40rem;

  /* white */
  background: #ffff;
  /* wolf-300 */
  border: 1px solid #ffff;
  box-sizing: border-box;
  border-radius: 0 0 1rem 1rem;
}

.card-image {
  border-radius: 1rem 1rem 0 0;
  width: 20rem;
}

.separator {
  width: 100%;
  border-bottom: solid 1px;
  position: relative;
  margin: 10px 0px;
  border-color: #b8b8b8;
}

.button {
  display: flex;
  align-items: center;
  margin: 1em 0.5em 0.5em 1.3125em;
}
