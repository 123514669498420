@import "../../../../styles/config";

.root {
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 2px;
  background: $wolf-200;
  border-radius: 9999px;

  &.selected {
    background: $pacific-primary-200-default;
  }
}
