@import "../../../styles/config";

.root {
  display: flex;
  align-items: center;
}

.icon {
  svg {
    @include icon-size($grid-unit-30);

    @media (max-width: $breakpoint-mobile) {
      @include icon-size($grid-unit-20);
    }
  }
}

.pageInfo {
  width: max-content;
  display: flex;
  align-items: center;
  padding: 0 $grid-unit-30;

  @media (max-width: $breakpoint-mobile) {
    padding: 0 $grid-unit-20;
  }

  span {
    @include body-lg;
    @media (max-width: $breakpoint-mobile) {
      @include font-size-body-md;
    }
  }
}

.pageInput {
  @include body-lg;
  text-align: center;
  max-width: $grid-unit-50;
  padding: $grid-unit-05 $grid-unit;
  background-color: $white;
  border: 1px solid $border;
  border-radius: 4px;

  @media (max-width: $breakpoint-mobile) {
    @include font-size-body-md;
    max-width: $grid-unit-40;
    padding: $grid-unit-025;
  }
}

.pageDivider {
  display: inline-block;
  padding-left: $grid-unit-15 !important;
  @media (max-width: $breakpoint-mobile) {
    padding-left: $grid-unit !important;
  }
}

.pageCount {
  display: inline-block;
  padding-left: 8px !important;
}
