@import "../../../styles/config";

$tones: "1000", "900", "800", "700", "600", "500", "400", "300", "200", "100";
$weights: "400", "600";
$whiteSpaces: "normal", "nowrap", "pre", "pre-wrap", "pre-line", "break-spaces";

// variants

.jumbo {
  @include jumbo;
}

.heading-1 {
  @include heading-1;
}

.heading-2 {
  @include heading-2;
}

.heading-3 {
  @include heading-3;
}

.heading-4 {
  @include heading-4;
}

.heading-5 {
  @include heading-5;
}

.body-lg {
  @include body-lg;
}

.body-md {
  @include body-md;
}

.body-sm {
  @include body-sm;
}

.no-wrap {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// align

.center {
  text-align: center;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.justify {
  text-align: justify;
}
.inherit {
  text-align: inherit;
}

// weights

.weight400 {
  font-weight: 400;
}

.weight600 {
  font-weight: 600;
}

// white space

@each $value in $whiteSpaces {
  .whiteSpace-#{$value} {
    white-space: #{$value};
  }
}

// colors

@include text-color("color-");
