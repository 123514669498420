@import "../../../styles/config";

.root {
  .steps {
    display: flex;
    flex-direction: column;
    width: 20px;
    height: 30px;
    opacity: 0;
    .up,
    .down {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0;
      background: transparent;
      border: 0;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        margin-left: -3px;
        width: 1px;
        height: 1px;
        border: 3px solid transparent;
      }
    }
    .up {
      &::before {
        top: 50%;
        margin-top: -4px;
        border-bottom: 5px solid $wolf-600;
      }
      &:hover {
        background: $wolf-100;
        &::before {
          border-bottom-color: $pacific-primary-600-default;
        }
      }
    }
    .down {
      &::before {
        bottom: 50%;
        margin-bottom: -4px;
        border-top: 5px solid $wolf-600;
      }
      &:hover {
        background: $wolf-100;
        &::before {
          border-top-color: $pacific-primary-600-default;
        }
      }
    }

    // disabled
    &.disabled {
      .up {
        &::before {
          border-bottom: 5px solid $wolf-400;
        }
      }
      .down {
        &::before {
          border-top: 5px solid $wolf-400;
        }
      }
    }

    // size
    &.small {
      height: 22px;
    }
    &.large {
      height: 34px;
    }
    &.jumbo {
      height: 46px;
    }
  }

  // end icon
  :global(.PP-InputBase-endIcon) {
    padding: 0 4px 0 0 !important;
  }

  // disabled
  &.disabled {
    pointer-events: none;
  }

  .container {
    // hover
    &:hover {
      .steps {
        opacity: 1;
      }
    }

    //focus
    &:focus-within {
      .steps {
        opacity: 1;
      }
    }
  }
}
