@import "../../../styles/config";

.container {
  display: flex;
  gap: $grid-unit-20;
  align-items: flex-start;

  &.vertical {
    flex-direction: column;
  }

  &.small {
    .option .label {
      @include font-size-body-sm;
      margin-left: $grid-unit-10;
    }
  }
  &.medium {
    .option .label {
      @include font-size-body-lg;
    }
  }
  &.large {
    .option .label {
      @include font-size-heading-5;
    }
  }
  &.jumbo {
    .option .label {
      @include font-size-heading-5;
    }
  }

  &.error {
    .option {
      .input {
        &:hover {
          + .control {
            box-shadow: 0 0 0 3px rgba($pacific-primary-600, 0.15);
          }
        }
        &:focus-within {
          + .control {
            box-shadow: 0 0 0 3px rgba($pacific-primary-600, 0.15);
            border-color: $wild-watermellon-600;
          }
        }
        &:checked {
          + .control {
            border-color: $wild-watermellon-600;
          }
        }
      }
      .control {
        border: 1px solid $wild-watermellon-600;
      }
    }
  }
}
