@import "../../../styles/config";

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $grid-unit-30;

  @media (max-width: $breakpoint-mobile) {
    margin-bottom: $grid-unit-20;
  }
}

.title {
  @include heading-3;
  color: $wolf-800;
  flex: 1;
  margin-right: $grid-unit-20;

  @media (max-width: $breakpoint-mobile) {
    @include font-size-body-lg;
    margin-right: $grid-unit;
  }
}

.action-menu {
  display: flex;
  gap: $grid-unit-30;
  align-items: center;

  @media (max-width: $breakpoint-mobile) {
    gap: $grid-unit-15;

    svg {
      @include icon-size(16px);
    }
  }
}
