@import "../../../styles/config";

.container {
  @include reset;
  background: transparent;
  cursor: pointer;
  border: 1px solid black;

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
