@import "../../../styles/config";

.container {
}

.time-container {
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  font-family: Lato;
  color: $wolf-800;
  font-weight: normal;
  border: 1px solid #dae0e6;
}

.spacer {
  border-right: 1px solid #dae0e6;
  border-left: 1px solid #dae0e6;
  padding: 8px 16px;
  font-size: 14px;
}

.time-container-select select {
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  background-color: transparent;
  border: none;
  //padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: Lato;
  font-size: 14px;
  cursor: inherit;
  line-height: inherit;
  position: relative;
  padding: 8px 12px;

  // Stack above custom arrow
  z-index: 1;

  // Remove dropdown arrow in IE10 & IE11
  // @link https://www.filamentgroup.com/lab/select-css.html
  &::-ms-expand {
    display: none;
  }

  // Remove focus outline, will add on alternate element
  outline: none;
}

.time-container-select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  .time-container-select select,
  &::after {
    grid-area: select;
  }

  min-width: 11ch;
  cursor: pointer;

  // Optional styles
  // remove for transparency
  background-color: #fff;
}

.time-container-select svg {
  position: absolute;
  right: 8px;
  transform: scale(0.7);
}

// Interim solution until :focus-within has better support
.time-container-select select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid var(--select-focus);
  border-radius: inherit;
}

.time-container-select select[multiple] {
  padding-right: 0;

  /*
     * Safari will not reveal an option
     * unless the select height has room to 
     * show all of it
     * Firefox and Chrome allow showing 
     * a partial option
     */
  height: 6rem;

  .time-container-select select option {
    white-space: normal;

    // Only affects Chrome
    outline-color: var(--select-focus);
  }
}

.select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}
