/* Adhering to an 8-px grid */

$grid-unit: 8px;
$grid-unit-025: $grid-unit * 0.25; /* 2px */
$grid-unit-05: $grid-unit * 0.5; /* 4px */
$grid-unit-075: $grid-unit * 0.75; /* 6px */
$grid-unit-10: $grid-unit * 1; /* 8px */
$grid-unit-15: $grid-unit * 1.5; /* 12px */
$grid-unit-20: $grid-unit * 2; /* 16px */
$grid-unit-25: $grid-unit * 2.5; /* 20px */
$grid-unit-30: $grid-unit * 3; /* 24px */
$grid-unit-40: $grid-unit * 4; /* 32px */
$grid-unit-50: $grid-unit * 5; /* 40px */
$grid-unit-60: $grid-unit * 6; /* 48px */
$grid-unit-70: $grid-unit * 7; /* 56px */
$grid-unit-80: $grid-unit * 8; /* 64px */
