@import "../../../styles/config";

.container {
  position: relative;
  width: fit-content;
  line-height: 0;
}

.icon-button {
  @include reset;
  background-color: transparent;
  border-radius: 200px;
  cursor: pointer;

  &.active,
  &:active {
    color: $brand;
  }

  &:disabled {
    color: $disabled;
    pointer-events: none;
    opacity: 0.5;
  }
  &:hover {
    svg {
      color: $pacific-primary-600;
    }
  }
  &:focus {
    box-shadow: 0 0 2px 4px $pacific-primary-200;
    background: rgba($pacific-primary-300, 0.2);
  }
}

.highlight {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 200px;
  z-index: -1;
  transform: scale(1.5);
  opacity: 0;
  background-color: $wolf-200;

  &.hovering {
    opacity: 1;
    transition: all 250ms ease-in-out;
  }
}

.tooltip {
  z-index: $tooltip-z-index;
}
